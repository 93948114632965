import React,{useState, useEffect} from 'react'
import Logo from '../assets/img/logo.png'
import {useNavigate, useLocation} from 'react-router-dom'
import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"
import axios from 'axios'
import {ReactComponent as Svodka} from '../assets/img/static.svg'
import {ReactComponent as Potoki} from '../assets/img/potok.svg'
import {ReactComponent as March} from '../assets/img/math.svg'
import {ReactComponent as Clubs} from '../assets/img/club.svg'
import {ReactComponent as Personal} from '../assets/img/personal.svg'
import {ReactComponent as Seting} from '../assets/img/setting.svg'
import {ReactComponent as Notification} from '../assets/img/notification.svg'
import {ReactComponent as Account} from '../assets/img/account.svg'
import {ReactComponent as Logaut} from '../assets/img/logaut.svg'


const listData = [
    {id:1, name:'Сводка', img:<Svodka/>, rout:'summary'},
    {id:2, name:'Потоки', img:<Potoki/>,rout:'feeds', marker: 'live'},
    {id:3, name:'Матчи', img:<March/>, rout:'matches', marker: 'empty'},
    {id:4, name:'Клубы', img:<Clubs/>, rout:'clubs'},
    {id:5, name:'Персонал', img:<Personal/>, rout:'employees'},
]

const listDataSet=[
    {id:6, name:'Settings', img:<Seting/>, rout:'settings'},
    {id:7, name:'Notifications', img:<Notification/>, rout:'notifications'},
]


const listDataAccaunt=[
    {id:8, name:'Account', img:<Account/>, rout:'account'},
    {id:9, name:'Logaut', img:<Logaut/>, rout:'/'}

]

const Navbar =inject("mainStore")(observer(({mainStore}) => {
    const link = computed(() => mainStore.getMainStore("link")).get()

    const navigation = useNavigate();
    const location =useLocation()
    const active = location.pathname.slice(1)
    const [itemLink, setItemLink] = useState(false)
    const [prod, setProd]= useState(link.includes('stats'))
    const [activ, setActiv]= useState(2)
    const [data, setData] = useState({})


    useEffect(() => {
        mainStore.getInitData(link,(result) => {
            if(result){

                setData(result)
            }
        })
    },[link])


    const onClickNavBar=(id, rout)=>{
        setActiv(id)
        navigation(`/${rout}`)
    }

    const onClickBack=()=>{
        if(prod){
            localStorage.setItem('backLink', `https://${prod ? 'staging' : 'stats'}.amateum.com/`)
            mainStore.setMainStore('link', `https://${prod ? 'staging' : 'stats'}.amateum.com/`)
        }else{
            localStorage.setItem('backLink', `https://stats.amateum.com/`)
            mainStore.setMainStore('link', `https://${prod ? 'staging' : 'stats'}.amateum.com/`)
        }
        setProd(!prod)
    }

    return(
        <div className='navbar'>
            <div>
                <img className='navbar__logo' src={Logo}/>

                <div className={`switch__link ${prod  ? 'active' : ''}`}>
                    <span>Test</span>
                    <div className={`switch__btn ${prod ? 'switch__btn-active' : ''}`} onClick={()=>{onClickBack()}}></div>
                    <span>Production</span>
                </div>

                <div className='navbar__list'>
                    {listData.map((it)=>(
                        <div className={`navbar__item ${active===it.rout ? 'activ': ''}`} key={it.id} onClick={()=>onClickNavBar(it.id, it.rout)}>
                            <div className={`item__img ${active===it.rout ? 'activ': ''}`}>{it.img}</div>
                            <span className={`item__title`}>{it.name}</span>
                            {it.marker && data[it.marker] ? <div className='item__notification'>{data[it.marker]}</div> : null}
                        </div>

                    ))}
                </div>

                <div className='navbar__list setting'>
                    {listDataSet.map((it)=>(
                        <div className={`navbar__item ${active===it.rout ? 'activ': ''}`} key={it.id} onClick={()=>onClickNavBar(it.id, it.rout)}>
                            <div className={`item__img ${active===it.rout ? 'activ': ''}`}>{it.img}</div>
                            <span className={`item__title`}>{it.name}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div>
                <div className='navbar__list account'>
                    {listDataAccaunt.map((it)=>(
                        <div className={`navbar__item ${active===it.rout ? 'activ': ''}`} key={it.id} onClick={()=>onClickNavBar(it.id, it.rout)}>
                            <div className={`item__img ${active===it.rout ? 'activ': ''}`}>{it.img}</div>
                            <span className={`item__title`}>{it.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}))
export default Navbar
