import React,{ useState, useEffect } from 'react'
import CollectionPlay from '../../assets/img/collection-play.svg'
import Terminal from '../../assets/img/terminal.svg'
import { ToastContainer, toast } from 'react-toastify'


const CopiLink = ({link=false, label, title, onChange}) =>{

    const [text, setText] = useState('')

    useEffect(()=>{
        if(title){
            setText(title)
        }else{
            setText('')
        }
    },[title])

    const onChangeInput =(e)=>{
        setText(e)
        onChange(e)
    }

    const onClickCopi = () =>{
        navigator.clipboard.writeText(`${title}`)
        toast.success('Вы скопировали', {position: "top-left", autoClose: 1000 })
    }

    return (
        <div className="copi__link">

            <span className='copi__link_label'>{label}</span>

            <div className="copi__link_input" >
                <img src={link ?  CollectionPlay : Terminal} onClick={()=>onClickCopi()}/>
                <input className='link_input-text' value={text} onChange ={(e)=>onChangeInput(e.target.value)}/>
            </div>

        </div>

    )
}
export default CopiLink
