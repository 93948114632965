import React,{useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { computed, toJS } from 'mobx';


const Warning = inject('mainStore')(observer(({ mainStore, onClick}) => {
    const navigation = useNavigate();
    const location = useLocation()

    return (
        <div className='warning__modal'>
            <span className='warning__modal_title'>Вы действительно хотите удалить статиста?</span>
            <div className='warning__modal_btn-group'>
                <div className='btn-group delete' onClick={()=> onClick()}>Удалить</div>
                <div className='btn-group cancel' onClick={() =>navigation(-1) }>Отменить</div>

            </div>
        </div>
    )
  }))

export default Warning;
