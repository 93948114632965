import React, {useState, useEffect, useRef} from "react"
import {useParams, useLocation, useNavigate, Redirect} from "react-router-dom"

import {inject, observer} from "mobx-react"
import {computed, action, toJS} from "mobx"
import { Button, Header, Input, FeedsInfo } from '../Common'
import moment from 'moment'
import CustomScrollbars from 'react-custom-scrollbars-2'
import '../../assets/scss/Staffs.scss';
import Potok from '../../assets/img/potoki.svg'
import service from '../../stores/service';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../Modal'


const Staffs = inject("mainStore")(observer(({mainStore}) => {
        const link = computed(() => mainStore.getMainStore("link")).get()
        const navigation = useNavigate();
        const location = useLocation()

        const [data, setData] = useState([])
        const [load, setLoad] = useState(false)
        const [staffs, setStaffs] = useState([]);
        const [msgError, setMsgError] = useState(null)
        const [activeItem, setActiveItem] = useState(false);
        const [filtred, setFiltred] = useState([])
        const [filterData, setFilterData] = useState([])
        const [item, setItem]= useState(false)
        const [tab, setTab] = useState('olds')
        const [term, setTerm] = useState('');
        const [loader, setLoader] = useState(false)

        useEffect(() => {
            setLoader(true)
            mainStore.getStaffs(link,(result) => {
                if(result.success){
                    setLoader(false)
                    setData(result)
                    setFilterData(result.data.filter(el=> el.range !==`junior`))
                }
            })
        },[link, load])


        const onSaveStaff = async (staff) => {
            const { _id, name, login, baseReward, range, telegramLink, isStaging, payouts, curPayout} = staff;
            const body = { name, login, baseReward: +baseReward, range, telegramLink, isStaging };

            if(staff._id){
                const updStaff = await mainStore.sendPostData(link, 'staffs',{_id: _id, ...body},(result =>{
                    setData(result)
                }))

                if (updStaff && updStaff._id) {
                    const indx = staffs.findIndex(item => item._id.toString() === _id.toString());
                    if ( baseReward ) {
                        let balance = 0;
                        if (curPayout && !curPayout.completed) {
                            curPayout.reward = +baseReward * curPayout.feeds.reduce((acc, cur) => {
                                acc += cur.reward;
                                return acc
                            }, 0)
                            balance += curPayout.reward;
                            if (payouts && payouts.length) {
                                const filtredp = payouts.filter(item => !item.completed);
                                if (filtredp && filtredp.length) {
                                    const sum = filtredp.reduce((acc, cur) => {
                                        acc += cur.reward;
                                        return acc
                                    }, 0)
                                    balance += sum;
                                }

                            }
                            staff.curPayout = curPayout
                        }
                        staff.balance = balance;
                    }
                    /*const indxf = filtred.findIndex(item => item._id.toString() === _id.toString());
                    setFiltred([...filtred.slice(0, indxf), {...staff, _id: _id}, ...filtred.slice(indxf+1)]);*/

                    // setStaffs([...staffs.slice(0, indx), {...staff, _id: _id}, ...staffs.slice(indx+1)]);

                    setActiveItem({...staff, _id: _id});

            }
        }else {mainStore.sendPostData(link, 'staffs',body, (result) =>{
                     if (result && result.success) {
                         setData(result);
                         setActiveItem({_id: result._id, ...staff});
                     } else {
                         alert('данные не были отправлены на сервер2')
                     }
                })
            }
    }

        const deleteStaff = () =>{
            const deletItem = {...activeItem, archived: true}
            mainStore.deletePostData(link, deletItem ,(result)=>{
                if(result){
                    let newData = data.data.filter(el=> result.data._id !== el._id)
                    setData({success: true , data:[...newData]})
                    setActiveItem(false)
                    navigation(-1)
                }
            })
        }

        const openModalWarning =()=> {
            navigation(location.pathname+'?modal=warning', {
                    state: {
                        modal: 'warning'
                    }
                }
            )
        }

        const onChange = (e) => {
            setTerm(e)
            const itemSort = data.data.filter((el) => el.name.toLowerCase().includes(e.toLowerCase()))
            console.log(itemSort);
            setFilterData(itemSort)
        }

        const onClickTab =(idTab)=>{
            setTab(idTab)

            switch(idTab){
                case 'all':
                    setFilterData(data.data)
                    setActiveItem(false)
                break;

                case 'junior':
                    setFilterData(data.data.filter(el=> el.range ===`${idTab}`))
                    setActiveItem(false)
                break;

                case 'olds':
                    setFilterData(data.data.filter(el=> el.range !==`junior`))
                    setActiveItem(false)
                break;
            }
        }

        return (
            <>
                <div className='header__text_tab'>
                    {tabBtn.map((it)=>(
                        <div key={it.id} className={`text__tab_title ${tab===it.id?  'active' : ''}`} onClick={() => {onClickTab(it.id);}}>
                            <span>{it.name}</span>
                        </div>
                    ))}
                    <Input
                        type='text'
                        value={term}
                        name='name'
                        onChange={onChange}
                        placeholder='Введите ФИО статиста'
                    />
                </div>
                {loader ? <div className='loader'></div> : <div className="staffs">
                    <Button
                        title='Создать'
                        icon={require('../../assets/img/plus.png')}
                        type='createBtn'
                        size='medium'
                        onClick={() => setActiveItem(defStaff)}
                    />
                    {data.data ? <div className="staffs__list">
                        <Modal onClick={deleteStaff}/>
                        <StaffPreviewHeader />
                        <div className='staffs__table'>
                                {filterData.length > 0 ?
                                    filterData.sort((a,b) => a.name - b.name).map(staff => <StaffPreview staff={staff} setActiveItem={setActiveItem} activeItem={activeItem} key={staff._id}/>)
                                    :
                                    data.data.sort((a,b) => a.name - b.name).map(staff => <StaffPreview staff={staff} setActiveItem={setActiveItem} activeItem={activeItem} key={staff._id}/>)}
                        </div>
                    </div> : null }
                    {/*<div className='staff__item-container'>*/}
                        {activeItem ?   <StaffItem staff={activeItem} onSaveStaff={onSaveStaff} openModalWarning={openModalWarning} />
                                        :
                                        <div className='staff__empty'>
                                            <img src={Potok}/><br/>
                                            <span>кликните на карточку статиста, чтобы <br/>
                                            редактировать его</span>
                                        </div> }
                    {/*</div>*/}
                </div>}
            </>
        )
    })
)

const StaffPreviewHeader = () => {
    return  <div className='staff staff--header'>
                <div className='staff__status'>
                </div>
                <div className='staff__info'>
                    Статист
                </div>
                <div className='staff__feeds'>
                    фидов
                </div>
                <div className='staff__balance'>
                    баланс
                </div>
                <div className='staff__upcoming'>
                    назн.
                </div>
                <div className='staff__weekupc'>
                    П.Н.
                </div>
            </div>
}

const StaffPreview = ({ staff, setActiveItem, activeItem }) => {
    const [ data, setData ] =  useState(staff)
    useEffect(() => {
        if (staff) {
            setData(staff)
        }
    }, [staff])

    const getRandomIndx = max => {
        return Math.floor(Math.random() * max)
    }

    const today = moment().format('YY-MM-DD');
    const weekago = moment().add(-1, 'weeks').format('YY-MM-DD');

    const cl = ['warn', 'success', 'secondary', 'primary'];

    const { balance, name, finished, upcomming, telegramLink, avatarUrl, range} = data;
    const lastWeekUpcomming = finished ? finished.filter(item => item.match.date < today && item.match.date >= weekago ): [];
    const fio = avatarUrl ? null : name.split(' ');

    const init = fio ? fio[1] ? `${fio[0][0]}${fio[1][0]}` : fio[0][0] : null;
    const bg = init ? cl[getRandomIndx(cl.length)]: null

    return <div className={`staff ${activeItem._id === staff._id ? 'active' : ''}`} onClick={() => {setActiveItem(staff)}}>
                <div className={`staff__status ${range}`}>

                </div>

                <div className='staff__info'>
                    <div className={`staff__ava ${init ? `${bg}` : null}`}>
                        {avatarUrl ? <img src={avatarUrl}/> : init}
                    </div>
                    <div className='staff__name'>
                        {name}
                    </div>
                    <div className={`staff__tg ${telegramLink ? '' : 'empty'}`}>
                        {telegramLink ? <a href={telegramLink} target='_blank'><span src='../../assets/img/message_link.png'></span></a> : null}
                    </div>
                </div>

                <div className='staff__feeds'>
                    {finished && finished.length ? finished.length : '-'}
                </div>

                <div className='staff__balance'>
                    ₽ {balance || 0}
                </div>

                <div className='staff__upcoming'>
                    {upcomming && upcomming.length ? upcomming.length : '-'}
                </div>

                <div className='staff__upcoming'>
                    {lastWeekUpcomming && lastWeekUpcomming.length ? lastWeekUpcomming.length : '-'}
                </div>
            </div>
}

const PayItem = ({pay}) => {
    const { reward, created, completed } = pay;

    return  <div className='pay'>
                <div className='pay__reward'>
                    ₽ {reward}
                </div>
                <div className='pay__created'>
                    {moment(created, 'YY-MM-DD').format('DD MMMM')}
                </div>
                <div className='pay__complete'>
                {completed ? <Button size='small' title='PAID' type='success' /> : null}
                </div>

            </div>
}
const ranges = [{key: 'junior', value: 'Junior'}, {key: 'middle', value: 'Middle'}, {key: 'senior', value: 'Senior'}];

const defStaff = {
    name: '',
    login: '',
    telegramLink: '',
    baseReward: 500,
    range: ranges[0].key,
    delete:false
}
const feedCounts = [2, 3, 5, 8, 13, 21];

const StaffItem = ({staff, onSaveStaff, openModalWarning}) => {
    //const { balance, name, login, telegramLink, payouts} = staff;
    const [form, setForm] = useState({...staff})
    //const [curPayFeeds, setCurPayFeeds] = useState('');
    const [feedCount, setFeedCount] = useState(5);
    const [feedsInfo, setFeedsInfo] = useState(null);

    useEffect(() => {
        if (staff) {
            setForm({...staff})
        }
    }, [staff])

    useEffect(() => {
        async function fetchData() {
            if (feedCount && form._id) {
                const data = await service.getData(`admin/avgFeedsStaff/?dataId=${form._id}&count=${feedCount}`)
                if (data) {
                    setFeedsInfo(data)
                }
            }
        }
        fetchData();
    }, [feedCount, form])

    const toastId = useRef(null)

    /*useEffect(() => {
        if (form) {
            const { curPayout } = form;
            if (curPayout) {
                const { feeds, reward } = curPayout;
                const { baseReward } = form;
                if (feeds && feeds.length) {
                    const mapFeeds = feeds.reduce((acc, cur) => {
                        const { match } = cur
                        const text = `${match.date} ${match.time} ${match.home.fullName} 🆚 ${match.away.fullName} ${baseReward * cur.reward}₽\n`
                        return acc + text
                    },'');
                    const output = mapFeeds + '--------------------------\n' + `Total: ${reward}₽`
                    console.log('mapFeeds', output );
                    setCurPayFeeds(output);
                    //return output
                }
            }
        }
    }, [form.baseReward])*/

    const { balance=0, name='', login='', telegramLink='', baseReward='', range='', payouts='', curPayout='', isStaging=''} = form

    const curRange = ranges.find(r => r.key === range);
    /*
    const [name, setName] = useState(name);
    const [login, setLogin] = useState(login)
    const [telegramLink, setTGLink] = useState(telegramLink)
    const [range, setRange] = useState(range)
    const [baseReward, setBaseReward] = useState(baseReward)
*/
    const createPayout = async () => {
        if (curPayout) {

            const pay = await service.postData(null, 'payouts', curPayout);

            if (pay && pay._id) {
                if (payouts) {
                    setForm({...form, payouts: [...payouts, {...curPayout, _id: pay._id}]})
                } else {
                    setForm({...form, payouts: [{...curPayout, _id: pay._id}]})
                }

            } else {
                alert('Данные по оплате не загружены')
            }
        }
    }

    const summaryFeedsCopy = async () => {
        try {
            if (form) {
                const { curPayout } = form;
                if (curPayout) {
                    //toastId.current =
                    //toast.success('text is coping', {position: "top-right", autoClose: 1000 })
                    const { feeds, reward } = curPayout;
                    const { baseReward } = form;
                    if (feeds && feeds.length) {
                        const mapFeeds = feeds.reduce((acc, cur) => {
                            const { match } = cur
                            const text = match ? `${match.date} ${match.time} ${match.home.fullName} 🆚 ${match.away.fullName} ${baseReward * cur.reward}₽\n` : null
                            return text ? acc + text : acc
                        },'');
                        
                        const output = mapFeeds + '--------------------------\n' + `Total: ${reward}₽`
                        //console.log('mapFeeds', output );
                        //setCurPayFeeds(output);
                        //return output
                        await navigator.clipboard.writeText(output);
                        toast.success('Вы скопировали', {position: "top-right", autoClose: 1000 })
                        //toast.update(toastId.current, {type: toast.TYPE.SUCCESS, render: () => 'text copied!', autoClose: 2500})
                        //console.log('text copied', toastId);
                    }
                }
            }

        } catch (e) {
            console.log('copied faild', e);
        }
    }

    const onHandle = ({target}) => {
        //console.log([target.name], target.value, typeof(target.value));
        setForm({...form, [target.name]: target.value})
    }



    return  <div className='staff__item'>
                <ToastContainer position='top-left' />
                <div className='staff__header'>
                    <div className='staff__desc'>Настройки профиля</div>
                    <div className='staff__isStaging'>
                        <span>Только тестовая БД</span>

                        <div className="switch__link isStaging" onClick={() => setForm({...form, isStaging: !form.isStaging})}>
                            <div className={`switch__btn ${isStaging ? 'switch__btn-active' : ''}`} ></div>
                            <span>{isStaging ? 'Вкл.' : "Выкл." }</span>
                        </div>

                    </div>
                </div>
                <div className='staff_form'>
                    <div className='staff__name'>
                        <label htmlFor='name'>Имя, Фамилия</label>
                        <input type='text' id='name' name='name' value={name} placeholder='Иван Иванов' onChange={onHandle} autoComplete='off' autoFocus/>
                    </div>
                    <div className='staff__social'>
                        <div className='staff__login'>
                            <label htmlFor='login'>Номер телефона</label>
                            <input type='text' id='login' name='login' value={login} onChange={onHandle} placeholder='8911111111' autoComplete='off'/>
                        </div>
                        <div className='staff__tg'>
                            <label htmlFor='telegramLink'>Ссылка на чат Telegram</label>
                            <input type='text' id='telegramLink' name='telegramLink' onChange={onHandle} value={telegramLink} placeholder='tg' autoComplete='off'/>
                        </div>
                    </div>
                    <div className='staff__rewards'>
                        <div className='staff__reward'>
                            <label htmlFor='baseReward'>Ставка, ₽</label>
                            <input type='number' name='baseReward' id='baseReward' value={baseReward} onChange={onHandle} placeholder='500' autoComplete='off'/>
                        </div>
                        <div className='staff__level'>
                            <label htmlFor='range'>Level</label>
                            <select name='range' onChange={onHandle} id='range' value={curRange.key} autoComplete='off'>
                                {ranges.map(r => (
                                    <option value={r.key} key={r.key} >{r.value}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='staff__btn_group'>
                        <div className='btn__group_add' onClick={() => onSaveStaff(form)}>Сохранить профиль</div>
                        {staff.delete===false ? null : <div className='btn__group_del' onClick={() => openModalWarning()}>Удалить</div>}


                    </div>
                </div>

                <div className='staff__money'>
                    <div className='staff__info'>
                        <div className='staff__info_balance'>
                            <div className='info__balance_block'>
                                <div className='staff__b'>
                                    <div className='balance'>
                                        <div className='balance--descr'>
                                            Баланс
                                        </div>
                                        <div className='balance--sum'>
                                            ₽ {balance}
                                        </div>
                                    </div>
                                    <div className='staff__b--card'>
                                    </div>
                                </div>
                                {curPayout ?
                                    <div className='staff__balance_group-btn'>
                                        <div className='group__btn' onClick={() => createPayout()}>Создать выплату</div>
                                        <div className='group__btn sammary' onClick={() => summaryFeedsCopy()}>Саммари</div>
                                    </div> : null}
                            </div>
                            {payouts && payouts.length ? <div className='staff__payouts'>
                                {payouts.map(pay=> <PayItem pay={pay} key={pay._id} />)}
                            </div> : null}
                        </div>

                        <div className='staff__feedsInfo'>
                            <div className='staff__feedsInfo-filter'>
                                <span>Качество фидов</span>
                                <select name='feedCount' onChange={(e) => setFeedCount(e.target.value)} id='feedCount' value={feedCount} autoComplete='off'>
                                    {feedCounts.map(f => (
                                        <option value={f} key={f} >{f}</option>
                                    ))}
                                </select>
                            </div>
                            {feedsInfo ? <FeedsInfo data={feedsInfo}/> : null}
                        </div>
                    </div>

                </div>
            </div>
}

const tabBtn = [
    {id: 'all', name:'Все'},
    {id: 'junior', name:'Тестовые'},
    {id: 'olds', name:'Боевые'}
]


export default Staffs
