import React,{useState} from 'react'

const Switch = ({active, setActive}) => {

    return(
        <div className={'switch'} onClick={() => {setActive(!active)}}>

            <div className={`switch__btn ${active ? 'active' : ''}`} ></div>

        </div>
    )
}

export default Switch
