import React, {useState, useEffect, useRef} from "react"
import {useParams, useLocation, useNavigate, Redirect} from "react-router-dom"
import Plus from '../../../assets/img/plus.svg'
import Tricolor from '../../../assets/img/tricolor.png'
import Rutub from '../../../assets/img/rutub.png'
import YouTube from '../../../assets/img/youtube.png'
import Yandex from '../../../assets/img/yandex.png'
import MatchTv from '../../../assets/img/matchtv.png'
import NoLink from '../../../assets/img/nolink.png'
import Ok from '../../../assets/img/ok.svg'
import { Input, Button, ProgressBar } from '../../Common'
import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"
import CustomScrollbars from 'react-custom-scrollbars-2'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import service from '../../../stores/service';
import moment from 'moment'
moment.locale('ru')

const MatchTable = inject("mainStore")(observer(({ mainStore, name, data, setItem, selected, base, setLoad, progressIndicator }) => {

    const linkImg = (link) => {
        if(link.includes('tricolor')){
            return <img src={Tricolor}/>
        }
        if(link.includes('matchtv')){
            return <img src={MatchTv}/>
        }
        if(link.includes('yandex')){
            return <img src={Yandex}/>
        }
        if(link.includes('rutub')){
            return <img src={Rutub}/>
        }
        if(link.includes('youtube')){
            return <img src={YouTube}/>
        }
        if(link.includes('')){
            return <img src={NoLink}/>
        }
    }

    const onClickCopi = (link) => {
        navigator.clipboard.writeText(`${link}`)
        toast.success('Вы скопировали ссылку', {position: "top-right", autoClose: 1000 })
    }

    const onClickInvait = (link) => {
        navigator.clipboard.writeText(`👋 Привет!\n Через 20 минут у тебя матч ⚽️\n—\nСсылка на трансляцию: ${link}\n—\nВ приложение зайти можно прямо сейчас, чтобы убедиться, что матч есть в списке.\n ⚠️ В сам матч, пожалуйста заходи за 3-4 минуты до начала, чтобы приложение получило максимально актуальные составы.`)
        toast.success('Вы скопировали инвайт', {position: "top-right", autoClose: 1000 })
    }

    const updateRosters = async item => {
        const res = await service.postData( base,`admin/actualizeRosterForMatch`, {matchId: item._id.toString()})
        //console.log(res);
        if (res.success) {
            const indx = data.findIndex(m => m._id.toString() === item._id.toString());

            data.splice(indx, 1, {...item, rosters: true})

            /*const updated = data.map(item => {
                if (item._id.toString() === id.toString()) {
                    return {...item, rosters: true}
                } else {
                    return item
                }
            })*/

            toast.success('Составы обновлены', {position: "top-right", autoClose: 1000 })
            setLoad(true)
            //setUpdData(updated)
        } else {
            toast.warning('Составы не обновлены', {position: "top-right", autoClose: 1000 })
        }
    }

    const generateRosters = async item => {
        const res = await service.postData(base,`admin/generateRosterForMatch`, {matchId: item._id.toString()})
        // console.log(res);
        // console.log(data);
        if (res.success) {
            const indx = data.findIndex(m => m._id.toString() === item._id.toString());

            data.splice(indx, 1, {...item, rosters: true})
            /*const updated = data.map(item => {
                if (item._id.toString() === id.toString()) {
                    return {...item, rosters: true}
                } else {
                    return item
                }
            })*/
            setLoad(true)
            toast.success('Составы сгенерированы', {position: "top-right", autoClose: 1000 })
            //setUpdData(updated)
        } else {
            toast.warning('Составы не сгенерированы', {position: "top-right", autoClose: 1000 })
        }
        //console.log('data.splice', data);
    }

    const checkRosters = item => {
        const { rosters } = item;
        if (rosters) {
            const { home, away } = rosters;
            if (home && away) {
                if (home.main && home.main.length && home.main.length === 11 && away.main && away.main.length && away.main.length === 11) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }
    const toastId = useRef(null)

    return(
        <table className='table'>
        <ToastContainer position='top-left' />
            <tbody className='table__block'>
                <tr className='table__block_name'>
                    {name.map((it) => (
                        <th style={{textAlign: (it.align || 'center')}} key={it.id} className="table__name-text">
                            {it.name}
                        </th>
                    ))}
                </tr>

                {progressIndicator !== null ? (
                    <ProgressBar time={progressIndicator} type='primary'/>
                ) : null}

                {data.map((item) => (
                    <tr style={!item.externalId ? {background: '#fffbfc'} : {}} key={item._id} className={`table__list_block ${selected === item._id ? "activ" : ""}`}>

                        <td style={{paddingLeft: 15}} className='list__block_title' onClick={() => setItem({...item})}>
                            <div className={`list__block_title-circle ${item.feeds && item.feeds.length ? 'green': 'red'}`}></div>
                            <div className='list__block_text' style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                                <div>{!item.feeds || !item.feeds.length ? 'нет назначений' : `Назначен${item.feeds.length > 1 ? 'о' : ''} ${item.feeds.length} поток${item.feeds.length > 1 ? 'а' : ''}`}</div>
                                {item.feeds ? (
                                    <div className='secondary'>
                                        {item.feeds.map(f => f.staff && f.staff.name ? f.staff.name : '').join(', ')}
                                    </div>
                                ) : null}
                            </div>
                        </td>

                        {checkRosters(item) ? (
                            <td style={{textAlign: 'center', paddingTop: 15}} onClick={() => setItem({...item})}><img src={Ok}/></td>
                        ) : (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                <Button size='small' type='success' title='Обновить' onClick={() => updateRosters(item)} style={{marginBottom: 5}}/>
                                <Button size='small' type='primary' title='Заполнить' onClick={() => generateRosters(item)} />
                            </div>
                        )}

                        {item.date ? <td className='list__block_text' onClick={() => setItem({...item})}>{item.time} {moment(item.date, 'YY-MM-DD').format('ddd, DD MMM')}</td> : null}
                        <td className='list__block_text match-teams' style={{position: 'relative'}} onClick={() => setItem({...item})}>
                            <span>{item.home.fullName}</span>
                            <img src={item.home.logo} />
                            <img src={item.away.logo} />
                            <span>{item.away.fullName}</span>
                            {!item.externalId ? (
                                <div style={{position: 'absolute', bottom: -12, textAlign: 'center', color: '#f44336', fontSize: 10, fontWeight: 'bold'}}>не заведён VSporte</div>
                            ) : null}
                        </td>

                        <td className='list__block_text' onClick={(e)=>e.stopPropagation()}>{item.broad}
                            <div className='block__link'>
                                {linkImg(item.metaInfo ? item.metaInfo.publicLink ? item.metaInfo.publicLink : '' : '')}

                                <div className='block__link_title'>
                                    {item.metaInfo && item.metaInfo.publicLink && <div onClick={()=>onClickCopi(item.metaInfo ? item.metaInfo.publicLink ? item.metaInfo.publicLink : '' : '')}>ссылка</div>}
                                    <div onClick={()=> onClickInvait(item.metaInfo ? item.metaInfo.publicLink ? item.metaInfo.publicLink : '' : '')}>инвайт</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
})
)

export default MatchTable
