import React, {useState, useEffect} from "react"
import {useParams, useLocation, Redirect} from "react-router-dom"

import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"



const Notification = inject("mainStore")(
    observer(({mainStore}) => {

        return (
            <>
                <div className="notification">
                    notification
                </div>

            </>
        )
    })
)

export default Notification
