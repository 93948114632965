import React,{useState, useEffect} from "react"
import SelectArrow from '../../assets/img/Select arrow.svg'


const Selector = ({list, onChange, plach='', marker={}}) => {

    const [open, setOpen] = useState(false)
    const [item, setItem] = useState(false)
    const [valueText, setValueText] = useState('')
    const [listSort, setListSort] = useState(list ? list : [])

    useEffect(()=>{
        if(marker && marker.type){
            let selectMarker = marker ?  list.filter(it => it.type === marker.type) : []
            setValueText(selectMarker.length > 0 ? selectMarker[0].name : '')
            onChange(marker.type)
        }
    },[marker])

    const onClickItem = (it) => {
        console.log(it);
        setOpen(false)
        setValueText(it && it.name ? it.name : '')
        setItem(it)
        onChange(it.type || it)
    }

    const handleChange = (e) => {
    
        setValueText(e)
        const itemSort = list.map((item) => { return {_id: item.id || item._id , name: item.name.toLowerCase(), type:item.type}}).filter((el) => el.name.includes(e.toLowerCase()))
        setListSort(itemSort)

    }

    return (
        <>
            <div className='select__block' onClick={() => setOpen(!open)}>

                <input className='select__block_name' placeholder={plach} onChange={(e) => handleChange(e.target.value)} onBlur={() => {setOpen(false)}} value={valueText} />


            </div>
            <div className={`select__list ${open? 'visible' : ''}`}>
                {listSort.map((it, idx) => (
                    <span className='select__list_item' onClick={() => onClickItem(it)} key={idx}>{it && it.name ? it.name : ""}</span>
                ))}
            </div>
        </>
    )
}
export default Selector
