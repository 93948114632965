import React, { useState, useEffect } from 'react'
import '../../assets/scss/progressBar.scss';

const ProgressBar = ({ time, type }) => {
    const [value, setValue] = useState(0);
    const [delta, setDelta] = useState(0);
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        let timeId
        const incBar = () => {
            //console.log('delta', delta, time);
            if (delta >= time) {
                setValue(0)
                setNow(new Date());
            } else {
                const perc = delta * 100 / time;
                //console.log('perc', perc);
                setValue(perc > 100 ? 100 : perc)
            }
            setDelta(new Date() - now)
        }
        timeId = setInterval(incBar, 50);
        return () => {
            if (timeId) {
                clearInterval(timeId)
            }
        }
    }, [delta, now])

    return  (
        <div className='customProgressBar'>
            <div className='bar'>
                <div style={{width: `${value}%`}} className={`${type ? type : 'info'}`}></div>
            </div>
        </div>
    )
}

export default ProgressBar
