import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/index.scss';
import Router from './containers/Router';

localStorage.setItem('tkn', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MmM4MmY2MjhhM2I0OTYxM2FiOGNlN2QiLCJsb2dpbiI6Im10YWxhcCIsInBhc3N3b3JkIjoibXRhbGFwIn0.jWgoMWcMoXCPmqcwAVYnLfFj-6ERsjbNVot5PZqdrgA')


if(localStorage.getItem('backLink') === null){localStorage.setItem('backLink', 'https://staging.amateum.com/')}
//if(localStorage.getItem('backLink') === null){localStorage.setItem('backLink', 'http://localhost:1100/')}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);
