import React from 'react'
import CustomScrollbars from 'react-custom-scrollbars-2'
import { ProgressBar } from '../../Common'
import Chat from '../../../assets/img/chat.svg'
import Ok from '../../../assets/img/ok.svg'


const PotokTable = ({ data, onClick, selected, setOpenRight, progressIndicator }) => {
    const tableName=[
        {id:1, align: 'left', name:'Таймер / МАРКЕРЫ'},
        {id:2, name:'основной'},
        {id:3, name:'МАТЧ'},
        {id:4, align: 'left', name:'статист'}
    ]

    const onClickItem =(item) =>{
        setOpenRight(false)
        onClick(item)

        setTimeout(()=>{
            setOpenRight(true)
        },200)
    }

    return (
        <table className='table__potok'>
            <tr className='table__block_name'>
            {tableName.map((it)=>(
                <th key={it.id} style={{textAlign: it.align || 'center'}} className="table__name-text">
                    {it.name}
                </th>
            ))}
            </tr>

            <tbody className='table__block'>
                {progressIndicator !== null ? (
                    <ProgressBar time={progressIndicator} type='primary'/>
                ) : null}

                {data && data.length ? data.map((item, idx) => {
                    const markersState = item.markersState==='ok' ? "green" : item.markersState === "warning" ? 'red' : 'orange'

                    return(
                        <tr key={item._id} className={`table__item_block ${selected === item._id ? "activ" : ""}`} onClick={() => {onClickItem(item)}}>

                            <td className='list__item_timer'>
                                <div className={`item__timer-circle ${!item.timer ? 'default' : markersState}`}></div>
                                <span className='item__title_text'>{!item.timer ? 'нет данных' : `${item.timer} / ${item.markersCount} / ${item.anonymousMarkers}% / ${item.underRefinementMarkers}%`}</span>
                            </td>

                            <td  className='list__item_canonical'>{item.canonical ? <img src={Ok}/> : null}</td>

                            <td className='list__item_match match-teams'>

                                <span className='item__match_name'>{item.match.home.fullName}</span>
                                <img src={item.match.home.logo} className='item__match_logo' />

                                <img src={item.match.away.logo} className='item__match_logo' style={{marginLeft:'12px'}}/>
                                <span className='item__match_name'>{item.match.away.fullName}</span>

                            </td>

                            <td className='list__item_staff'>
                                {item.staff.name}
                                {item.staff.telegramLink ? <img onClick={() => window.open(item.staff.telegramLink)} className='chat-link' src={Chat} /> : null}
                            </td>
                        </tr>
                    )
                }) : null}
            </tbody>
        </table>
    )
}

export default PotokTable
