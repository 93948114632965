import React,{useState, useRef} from 'react'
import Button from '../../assets/img/vertical.svg'
import Zvezda from '../../assets/img/Zvezda.svg'
import Delete from '../../assets/img/delet.svg'
import { useClickOutside } from '../../utils'



const UserBlock =({ data, isCanonical, onClickCanonical, link, onClickDelete })=>{
    const [appoint, setAppoint] = useState(false)
     const nodeRef = useRef();

    useClickOutside(true, nodeRef, () => setAppoint(false))

    return(
        <div className='user__block'>
            <div  className='user__block_logo'>
                {isCanonical ? <img src={Zvezda} className='user__block_logo-zvezda'/> : null }
            </div>

            <div className="user__block_name">
                <span className='block__name_title'>{data.staff && data.staff.name ? data.staff.name : ''}</span>
                <span className='block__name_desc'>{data.staff && data.staff.range ? data.staff.range : ''}</span>
            </div>

            <img src={Button} className='user__block__btn' onClick={()=> setAppoint(!appoint)}/>

            {appoint ?
                <div className='user__block_appoint' ref={nodeRef}>
                    <div className='block__appoint_add' onClick={()=> {onClickCanonical(data._id, '', link) ; setAppoint(false)}}>
                        <img src={Zvezda} className='user__block_logo-zvezda'/>
                        <span>Сделать основным</span>
                    </div>
                    <div className='block__appoint_delet' onClick={()=> {onClickDelete(data, link,); setAppoint(false)}}>
                        <img src={Delete} className='user__block_logo-zvezda'/>
                        <span>Удалить</span>
                    </div>

                </div> : null}

        </div>

    )
}

export default UserBlock
