import React, {useState, useEffect} from "react"
import {useParams, useLocation} from "react-router-dom"

import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"
import Summary from '../components/Pages/Summary'
import Potok from '../components/Pages/Potok/Potok'
import Clubs from '../components/Pages/Clubs'
import Match from '../components/Pages/Match/Match'
import Staffs from '../components/Pages/Staffs'
import Setting from '../components/Pages/Setting'
import Notification from '../components/Pages/Notification'
import Navbar from '../components/Navbar'
import Header from '../components/Common/Header'


import 'moment/locale/ru'

const Root = inject("mainStore")(observer(({mainStore}) => {
        const { firstParam } = useParams()
        const location = useLocation()

        let pageType =  firstParam ? firstParam : 'feeds'

        const wrap = {
            summary: {
                inner: Summary
            },
            feeds: {
                inner: Potok,
                title: 'Управление потоками'
            },
            clubs: {
                inner: Clubs,
            },
            matches: {
                inner: Match,
                title: 'Управление матчами'
            },
            employees: {
                inner: Staffs,
                title: 'Управление статистами',
                createBtn: true
            },
            settings: {
                inner: Setting,
            },
            notifications: {
                inner: Notification
            }
        }

        let Specified = wrap[pageType] ? wrap[pageType].inner : wrap.feeds

        return (
            <>
                <div id='content'>
                    <Navbar />
                    <div className='content__centre'>
                        <Header
                            title={wrap[pageType] ? wrap[pageType].title || '' : ''}
                        />
                        <div className="landing">
                            <Specified />
                        </div>
                    </div>
                </div>
            </>
        )
    })
)

export default Root
