import React from 'react'

const statsModel = {
    'possession': 'Владение мячом',
    'passesCompleted': 'Точные передачи',
    'shotsTotal': 'Удары всего',
    'shotsOnTarget': 'Удары в створ',
    'frameShots': 'Штанги / Перекладины',
    'corners': 'Угловые',
    'offsides': 'Оффсайды',
    'fouls': 'Нарушения',
    'yellowCards': 'Предупреждения',
    'redCards': 'Удаления'
}

const calcPerc = obj => {
    const total = obj.home + obj.away
    if(total === 0) {
        return '50%'
    } else {
        return Math.ceil((obj.home/total)*100)+'%'
    }
}

const BlockStat =({ data })=>{
    return Object.keys(statsModel).map((k, i) => {
        const row = data.find(r => r.key === k)
        if(row) {
            const perc = k === 'possession' ? row.home : calcPerc(row)

            return  <div className='block__stat' key={i}>
                        <div className='block__stat_name'>
                            <span>{row.home}</span>
                            <span>{statsModel[k]}</span>
                            <span>{row.away}</span>
                        </div>
                        <div className='block__stat_line'>
                            <div className='block__stat_line-blur' style={{width: perc}}></div>
                        </div>
                    </div>
        } else {
            return null
        }
    })
}

export default BlockStat
