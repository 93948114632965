import React, {useState, useEffect, useRef} from "react"

import { CardInfo } from '../Common'
import service from '../../stores/service';
const data2 = {
  _id: '62d68f9cff4d46108606d447',
  avgAnonymousMarkers: 2,
  avgUnderRefinementMarkers: 4,
  avgMarkersCount: 45,
  avgMatchMinutes: 61,
  avgMatchSeconds: 25,
  avgMarkersInMinute: 0.73
}

const formCount = count => {
    if (count < 10) {
        return `0${count}`
    } else {
        return count
    }
}
const FeedsInfo = ({data}) => {
    const { avgAnonymousMarkers, avgUnderRefinementMarkers, avgMarkersCount, avgMatchMinutes, avgMatchSeconds, avgMarkersInMinute } = data;

    return <div>
        <CardInfo
            title='Средний таймер'
            value={`${formCount(avgMatchMinutes)}:${formCount(avgMatchSeconds)}`}
            bgColor='#68DBF2'
            type='clock'
        />
        <CardInfo
            title='Маркеров на матч'
            value={avgMarkersCount}
            bgColor='#8CDFB3'
            type='list-stars'
        />
        <CardInfo
            title='Маркеров в минуту'
            value={avgMarkersInMinute}
            bgColor='#E1E8FF'
            type='hourglass'
        />
        <CardInfo
            title='Анонимные маркеры'
            value={avgAnonymousMarkers}
            bgColor='#68DBF2'
            type='percentage'
        />
        <CardInfo
            title='С уточнением'
            value={avgUnderRefinementMarkers}
            bgColor='#68DBF2'
            type='percentage'
        />
    </div>
}
export default FeedsInfo
