import React,{useState} from 'react'
import Load from '../../assets/img/load.svg'
import Plus from '../../assets/img/plus.svg'

const Header = ({ title }) => {


    return(
        <div className='header'>
            <div className='header__text'>
                <span className='header__text_title'>{title}</span>
            </div>
        </div>
    )
}

export default Header
