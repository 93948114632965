import React, {useState, useEffect, useRef} from "react"
import {useParams, useLocation, useNavigate, Redirect} from "react-router-dom"
import DatePiker from '../../Ui/DatePiker'
import DatePikerTime from '../../Ui/DatePikerTime'
import { Input, Button, ProgressBar } from '../../Common'
import moment from 'moment'
moment.locale('ru')



const CreateMatch = ({ match, setNewMatch, saveMatch, clubs }) => {
    const [form, setForm] = useState(match);
    const [homeTerm, setHomeTerm] = useState('');
    const [awayTerm, setAwayTerm] = useState('');
    const [homes, setHomes] = useState([]);
    const [aways, setAways] = useState([]);
/*    const [home, setHome] = useState(null);
    const [away, setAway] = useState(null);
*/
    useEffect(() => {
        setForm({...form, home: null})
        if (homeTerm) {
            setHomes(clubs.filter(item => item.fullName.toLowerCase().includes(homeTerm.toLowerCase())))
        } else {
            setHomes([]);
        }
    }, [homeTerm])

    useEffect(() => {
        setForm({...form, away: null})
        if (awayTerm) {
            setAways(clubs.filter(item => item.fullName.toLowerCase().includes(awayTerm.toLowerCase())))
        } else {
            setAways([]);
        }
    }, [awayTerm])

    const onChangeDate = (date) => {
        setForm({...form, date:date})
    }

    const onChangeTime = (newTime) => {
        setForm({...form, time: newTime})
    }

    return <div className='createMatchForm'>
        <div className='club-getters'>
            <div className='club-getter homegetter'>
                <Input
                    type='text'
                    value={form.home ? form.home.fullName : homeTerm}
                    name='home'
                    onChange={setHomeTerm}
                    placeholder='Введите название команды хозяев'
                />
                <div className='customselect'>
                    <div className={`select__list ${!form.home && homes.length ? 'visible' : ''}`}>
                        {homes.map((it) => (
                            <span className='select__list_item' onClick={() => setForm({...form, home: it})} key={it._id}>{it.fullName}</span>
                        ))}
                    </div>
                </div>

            </div>
            <div className='club-getter awaygetter'>
                <Input
                    type='text'
                    value={form.away ? form.away.fullName : awayTerm}
                    name='away'
                    onChange={setAwayTerm}
                    placeholder='Введите название команды гостей'
                />
                <div className='customselect'>
                    <div className={`select__list ${!form.away && aways.length ? 'visible' : ''}`}>
                        {aways.map((it) => (
                            <span className='select__list_item' onClick={() => setForm({...form, away: it})} key={it._id}>{it.fullName}</span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <div className='right__block_date'>
            <DatePiker onChange={onChangeDate} date={form ? form.date : match.date }/>
            <DatePikerTime onChange={onChangeTime} timer={form ? form.time : match.time}/>
        </div>

        <div className='actions-group'>
            <Button size='medium' type='success' title='Сохранить' onClick={() => saveMatch(form)} />
            <Button size='medium' type='primary' title='Закрыть' onClick={() => setNewMatch(null)} />
        </div>
    </div>
}


export default CreateMatch
