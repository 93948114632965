import React, {useState, useEffect} from 'react'
import moment from 'moment'

const DatePiker=({date='', onChange, id}) => {

const [formatDate, setFormatDate] = useState(false)

    useEffect(()=>{
        setFormatDate(moment(date, 'YY-MM-DD').format('YYYY-MM-DD'))
    },[id])

    const onChengeTarget = (e)=> {
        setFormatDate(e)
    }

    return (
        <div className='date-piker'>

            <span className='date-piker__title'>Дата</span>
             <input onChange={(e) => onChengeTarget(e.target.value)} type='date' value={formatDate} className='date-piker__input' onBlur={()=>{onChange(formatDate)}}/>
        </div>
    )
}

export default DatePiker
