import React from 'react'
import BlockStat from '../../../Ui/BlockStat'

const RightBlockSummary = ({data}) => {

    return (<>
                <div className='right__block_match-name'>

                    <div className='block__match_com'>
                        <img src={data.match.home.logo} className='block__match_com_img'/>
                        <span>{data.match.home.shortName}</span>
                    </div>

                    <div className='block__match_schet'>{data.snapshot &&  data.snapshot.stats[0].key === 'score' ? `${data.snapshot.stats[0].home} : ${data.snapshot.stats[0].away}` : '- : -'}</div>

                    <div className='block__match_com'>
                        <img src={data.match.away.logo} className='block__match_com_img'/>
                        <span>{data.match.away.shortName}</span>
                    </div>

                </div>

                <div className='right__block_statistika'>
                    <BlockStat
                        data={data.snapshot.stats}
                    />
                </div>

                {!data.canonical ? <div className='right__block_btn'>Сделать основным</div> : null}
            </>
    )
}
export default RightBlockSummary
