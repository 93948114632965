import React, { useState, useEffect } from 'react'
import service from '../../stores/service'
import RightBlock from './Potok/RightBlock/RightBlock'
import moment from 'moment'

import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"

moment.locale('ru')

const tableName=[
    {id:1, align: 'left', name:'статист'},
    {id:2, name:'Дата', align: 'center'},
    {id:3, name:'МАТЧ'},
    {id:4, align: 'center', name:'Critical Markers'}
]

const Summary = inject("mainStore")(observer(({mainStore}) => {
    const [list, setList] = useState([])
    const [selected, setSelected] = useState(null)
    const [feed, setFeed] = useState(null)

    useEffect(() => {
        service.fetchSummary()
            .then(summary => {
                setList(summary)
            })
    }, [])

    return  <div className="potoki">
                <div className='potoki__list'>
                    <table className='table__potok'>
                        <tr className='table__block_name'>
                        {tableName.map((it)=>(
                            <th key={it.id} style={{textAlign: it.align || 'center'}} className="table__name-text">
                                {it.name}
                            </th>
                        ))}
                        </tr>

                        <tbody className='table__block'>
                            {list.map((item, i) => (
                                <tr
                                    key={item._id}
                                    className={`table__item_block ${selected === item._id ? "activ" : ""}`}
                                    onClick={() => {
                                        setFeed({...item.feed})
                                        setSelected(item._id)
                                    }}
                                >
                                    <td>
                                        <div>{item.feed.statist.name}</div>
                                        <pre>{item.feed.match._id}</pre>
                                    </td>
                                    <td>{moment(item.feed.match.date, 'YY-MM-DD').format('D MMMM YY')}</td>
                                    <td className='list__item_match match-teams'>
                                        <span className='item__match_name'>{item.feed.match.home.fullName}</span>
                                        <img src={`https://amateum.fra1.digitaloceanspaces.com/vsporte/${item.feed.match.home.logoId}.png`} className='item__match_logo' />

                                        <img src={`https://amateum.fra1.digitaloceanspaces.com/vsporte/${item.feed.match.away.logoId}.png`} className='item__match_logo' style={{marginLeft:'12px'}}/>
                                        <span className='item__match_name'>{item.feed.match.away.fullName}</span>
                                    </td>
                                    <td style={{width: 200, color: '#f00', textAlign: 'center'}}><b>{item.list.length}</b></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {feed ? (
                    <RightBlock
                        data={{
                            ...feed,
                            match: {
                                ...feed.match,
                                home: {
                                    ...feed.match.home,
                                    logo: `https://amateum.fra1.digitaloceanspaces.com/vsporte/${feed.match.home.logoId}.png`
                                },
                                away: {
                                    ...feed.match.away,
                                    logo: `https://amateum.fra1.digitaloceanspaces.com/vsporte/${feed.match.away.logoId}.png`
                                }
                            }
                        }}
                        link='https://stats.amateum.com/'
                        setItem={setFeed}
                    />
                ) : (
                    <div className='potoki__info'>
                        <span className='info__text'>выберите поток для<br/> редактирования</span>
                    </div>
                )}
            </div>
}))

export default Summary
