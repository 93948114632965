import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useClickOutside } from '../utils'
import Warning from "../components/Modals/Warning";
import Notification from "../components/Modals/Notification";
import CreateMarker from '../components/Modals/CreateMarker'
import Close from '../assets/img/close.svg'

const Modal = ({onClick = ()=>{} , data, rosters, disable}) => {
  const location = useLocation();
  const navigation = useNavigate();
  const type = location && location.state && location.state.modal ? location.state.modal : false;

  const nodeRef = useRef();
  useClickOutside(type, nodeRef, () => navigation(-1))

  const modals = {
    warning: <Warning onClick={onClick}/>,
    notification: <Notification/>,
    createMarker: <CreateMarker data={data} rosters={rosters} onClick={onClick} disable={disable}/>

  };

  const Specified = type && modals[type] ? modals[type] : null;

  useEffect(() => {
      let body = document.body
      if (body && Specified) {
          // body.style.position = 'fixed';
          // body.style.top = '0px';

          body.style.overflow = 'hidden'
          body.style['touch-action'] = 'none';
          body.style['-webkit-overflow-scrolling'] = 'none';
          body.style['overscroll-behavior'] = 'none';

          body.style.width = '100%';
      }else{
          body.style = {}
          body.style.height = '100%'

      }
  }, [Specified])




  return (
    <div className={`modal ${Specified ? "modal-visible" : ""}`}>
      <div ref={nodeRef} className="modal__content">
        <img src={Close} className="modal__content_close" onClick={() => navigation(-1)} />
        {Specified}
      </div>
    </div>
  );
};

export default Modal;
