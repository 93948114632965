import {action, observable, computed, autorun, makeObservable, toJS, reaction} from "mobx"
//import {getPotok, getMatch, getStaff, getInit, postFeeds} from "./service"

import service from "./service"

const { getPotok, getMatch, getStaff, getInit, postFeeds, getStaffs, postData, putData, postCanonical, deleteData, deleteFeedId, postCompound, putMarker, deleteMarker, postMarker, getMarker} = service

class MainStore {

    mainStore = {
        staff:false,
        link: `${localStorage.getItem('backLink')}`

    }

    constructor(props) {
        makeObservable(this, {
            mainStore: observable,
            setMainStore: action,
        })
        this.initStore()
    }

    initStore = async () => {
        // this.getMatchData()
        this.getStaffData()
        // this.getInitData()
        // this.getStaffs()
    }

    getPotokData = (link, cb ) => {
            getPotok(link,(result) => {
                return cb(result)
                }
            )
    }

    getMatchData = (link, cb ) => {
            getMatch(link,(result) => {
                return cb(result)
                }
            )

    }

    getStaffData = () => {
            getStaff((result) => {
                this.setMainStore("staff", result)
                }
            )
    }

    getStaffs = async (link, cb) => {
            getStaffs(link, (result)=> {
                return cb(result)
            });

    }

    getInitData = (link, cb ) => {
            getInit(link,(result) => {
                return cb(result)
                }
            )
    }

    sendPostFeeds = (data, link, cb) => {
        postFeeds(data, (result) => {
            cb(result)
        })
    }

    sendPostCanonical = (id, sourceId={}, link, cb) => {
        postCanonical(id, sourceId, link, (result) => {
            cb(result)
        })
    }



    sendPostData = async (link, url, body, cb) => {
        // console.log(url);
        if (body) {
            if (body._id) {
                try {
                    const upd = await service.putData(link, url, body);
                    // console.log('upd', body, url, upd);
                    if (upd) {
                        if (url === 'matches') {
                            this.getMatchData(link, result => {
                                cb(result)
                            })
                        } else if (url === 'staffs') {
                            this.getStaffs(link, (result=>{
                                // console.log('result',result);
                                cb(result)
                            }))
                        }
                        return upd
                    } else {
                        return {error: true}
                        //alert('данные не были отправлены на сервер')
                    }
                } catch (e) {
                    console.log('put data err', e);
                    return {error: true}
                }
            } else {
                try {
                    const newData = await service.postData(link, url, body, cb);
                    // console.log('newData',newData);
                    if (newData && newData._id) {
                        if (url === 'matches') {
                            this.getMatchData(link, cb)
                        }else if (url === 'staffs') {
                            this.getStaffs(link, cb)
                        }
                        return newData
                    } else {
                        return {error: true}
                    }
                } catch (e) {
                    console.log('post data err', e);
                    return {error: true}
                }
            }
        } else {
            return {error: true, msg: 'body not found'}
        }
    }

    deletePostData = ( link,  body, cb) => {
        deleteData(link, body, (result) => {
            cb(result)
        })
    }

    deleteFeed = ( id , link, cb) => {
        deleteFeedId(id, link, (result) => {
            if(result){
                this.getMatchData(link, result=>{
                    cb(result)
                })
            }

        })
    }

    postCompoundData = (id, link, cb ) =>{
        let data ={
            matchId: id
        }
        postCompound( data, link, (result) =>{
            if(result){
                cb(result)
            }
        } )
    }
    
    getMarkerData = (id, link, cb) =>{
        getMarker(id, link, result=>{
            cb(result)
        })
    }

    postMarkerCreate = (data, link, cb ) =>{
        postMarker(data, link, result=>{
            if(result){
                cb (result)
            }
        })
    }

    putMarkerData = (data, markerId, link, cb) => {
        putMarker(data, markerId, link, (result)=>{
            if(result){
                cb(result)

            }
        })
    }

    deleteMarker = ( markerId , link, cb) => {
        deleteMarker(markerId, link, (result) => {
            if(result){
                cb(result)
            }
        })
    }

    getMainStore = (key) => {
        if (typeof this.mainStore[key] === "undefined") {
            return false
        } else {
            return toJS(this.mainStore[key])
        }
    }

    setMainStore = (key, value) => {
        this.mainStore[key] = value
    }
}
export default new MainStore()

const isEmpty = (obj) => {
    for (let key in obj) {
        // если тело цикла начнет выполняться - значит в объекте есть свойства
        return false
    }
    return true
}
