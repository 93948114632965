import React from "react"
import {BrowserRouter, Routes, Route} from "react-router-dom"
import {Provider} from "mobx-react"
import mainStore from "../stores/mainStore"

import Root from "./Root"


const stores = {
    mainStore,
}

const Routing = () => {
    return (
        <>
            <Provider {...stores}>
                <BrowserRouter>
                    <Routes>
                        <Route path={"/:firstParam/:secondParam/:thirdParam/:fourthParam"} element={<Root />} />
                        <Route path={"/:firstParam/:secondParam/:thirdParam"} element={<Root />} />
                        <Route path={"/:firstParam/:secondParam"} element={<Root />} />
                        <Route path={"/:firstParam"} element={<Root />} />
                        <Route path={"/"} element={<Root />} />
                    </Routes>
                </BrowserRouter>
            </Provider>
        </>
    )
}

export default Routing
