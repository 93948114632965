import React, { useState, useEffect } from 'react'
import '../../assets/scss/cardInfo.scss';

const CardInfo = ({title, value, bgColor, type, btns}) => {
    return  (
        <div className='card-container'>
        {type === 'percentage' ? (
            <div className='card percentage'>
                <div className='card-info'>
                    <div className='card-descr'>
                        {title}
                    </div>
                    <div className='card-value'>
                        {value}%
                    </div>
                </div>
                <div className='bar'>
                    <div style={{width: `${value}%`, backgroundColor: bgColor}}></div>
                </div>
            </div>
            ) : (
            <div className='card'>
                <div className='card-info'>
                    <div className='card-descr'>
                        {title}
                    </div>
                    <div className='card-value'>
                        {value}
                    </div>
                </div>
                <div className={`card-img ${type}`} style={{backgroundColor: bgColor}}></div>
            </div>
        )}

        {btns ? <div>
            {/*btns.map(item => <Button {...item}/>)*/}
            {btns.map(item => item)}
            {/*<Button type='success' size='small' onClick={() => createPayout()} title='Создать выплату'/>
        <Button type='primary' size='small' onClick={() => summaryFeedsCopy()} title='Саммари'/>*/}
        </div> : null}
        </div>
    )
}

export default CardInfo
