import React, { useState, useEffect } from 'react'
import '../../assets/scss/button.scss';

const Button = (props) => {
    const { onClick, icon, title, type, buttonImg, style } = props;
    let onClickcheckd
    if (typeof(onClick) === 'object') {
        if (Object.keys(onClick).length) {
            for (let key in onClick) {
                onClickcheckd = onClick[key];
                break;
            }
        } else {
            console.log('Button with onClick props without keys', props);
        }
    } else {
        onClickcheckd = onClick;
    }

    let classesText = 'button';
    for (let key in props) {
        if (['title', 'icon', 'onClick'].includes(key)) {
            continue;
        }
        classesText += ' ' + props[key];
    }

    return <div className = {classesText} onClick={type === 'notactive' ? null : (onClickcheckd || null)} style={style || {}}>
        {icon ? <span className='button--icon' style={{backgroundImage: `url(${icon})`, width: '24', height: '24'}}></span> : null} {title}
    </div>
}

export default Button
