import React, {useState, useEffect, useRef} from "react"
import {useParams, useLocation, useNavigate, Redirect} from "react-router-dom"
import MatchTable from './MatchTable'
import MatchRightBlock from './MatchRightBlock'
import CreateMatch from './CreateMatch'
import Cubock from '../../../assets/img/cubock.svg'
import Plus from '../../../assets/img/plus.svg'
import { Input, Button } from '../../Common'
import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import service from '../../../stores/service';
import moment from 'moment'
moment.locale('ru')

const tableName = [
    {id:1, width: '20%', name:'Статус', align: 'left' },
    {id:2, width: '15%', name:'Составы'},
    {id:3, width: '18%', name:'Время (MSK) / Дата', align: 'left'},
    {id:5, width: '35%', name:'МАТЧ'},
    {id:4, width: '12%', name:'broadcast', align: 'left'},
];

const emptyMatch = {
    home: null,
    away: null,
    date: moment().format('YY-MM-DD'),
    time: '17:00',
    status: 1
}
const timeReload = 20000;

const sorters = {
    live: (a,b) => moment(a.date+' '+a.time, 'YY-MM-DD HH:mm').unix() - moment(b.date+' '+b.time, 'YY-MM-DD HH:mm').unix(),
    upcoming: (a,b) => moment(a.date+' '+a.time, 'YY-MM-DD HH:mm').unix() - moment(b.date+' '+b.time, 'YY-MM-DD HH:mm').unix(),
    finished: (a,b) => moment(b.date+' '+b.time, 'YY-MM-DD HH:mm').unix() - moment(a.date+' '+a.time, 'YY-MM-DD HH:mm').unix()
}

const tabBtn= [
    {id: 'live', name:'LIVE'},
    {id: 'upcoming', name: 'Предстоящие'},
    {id: 'finished', name: 'Прошедшие'},
    {id: 'unassigned', name: 'Неназначенные'}
]

const filterUnassigned = obj => {
    return obj ? Object.keys(obj).reduce((acc, k) => {
        acc = acc.concat(obj[k].filter(m => !m.feeds || !m.feeds.length && moment(m.date, 'YY-MM-DD').unix() > moment().add(-5, 'days').unix()))
        return acc
    }, []).sort(sorters.upcoming) : []
}

const Match = inject("mainStore")(observer(({mainStore}) => {

        const staff = computed(() => mainStore.getMainStore("staff")).get()
        const link = computed(() => mainStore.getMainStore("link")).get()
        const [data, setData]= useState({finished:[], live:[], upcoming:[]})
        const staffData = staff ? staff : null
        const [item, setItem] = useState(null)
        const [load, setLoad]= useState(false)
        const [tab, setTab] = useState('live')
        const [allData, setAllData] = useState([])
        const [term, setTerm] = useState('');
        const navigation = useNavigate();
        const location = useLocation()
        const [newMatch, setNewMatch] = useState(null);
        const [clubs, setClubs] = useState([]);
        const [loader, setLoader] = useState(false)

        useEffect(() => {
            setLoader(true)

            const getClubs = async () => {
                const mdata = await service.getData(`admin/clubs`);
                if (mdata) {
                    setClubs(mdata);
                }
            }

            mainStore.getMatchData(link,(result) => {
                if(result){
                    if (!clubs || !clubs.length) {
                        //const allClubs = getClubsFromMatches(result);
                        getClubs()
                        //setClubs(allClubs);
                    }
                    setLoader(false)
                    setData(result);
                }
            })
        },[link, load])

        useEffect(() => {
            let timeId

            if (tab === 'live') {
                const getData = async () => {
                    const mdata = await service.getData(`admin/matches`);
                    if (mdata) {
                        setData(mdata);
                        if (item) {
                            const it = mdata.live.find(m => m._id.toString() === item._id.toString() );
                            if (it) {
                                setItem(it)
                            } else {
                                setItem(null);
                            }
                        }
                    }
                }

                timeId = setInterval(getData, timeReload);
            }
            return () => {
                if (timeId) {
                    clearInterval(timeId)
                }
            }
        }, [tab])

        const getClubsFromMatches = (obj) => {
            const cls = [];
            for (let key in obj) {
                if (obj[key].length) {
                    for (let m of obj[key]) {
                        const {home, away} = m
                        if (home && away && home._id && away._id) {
                            const indh = cls.findIndex(cl => cl._id.toString() === home._id.toString() );
                            const inda = cls.findIndex(cl => cl._id.toString() === away._id.toString() );
                            if (indh === -1) {
                                cls.push({_id: home._id, fullName: home.fullName, externalId: home.externalId})
                            }
                            if (inda === -1) {
                                cls.push({_id: away._id, fullName: away.fullName, externalId: away.externalId})
                            }
                        }
                    }
                }
            }
            return cls
        }
        const handleChange = (e) => {
            setTerm(e)
                const itemSort = data[tab].filter((el) => el.away.fullName.toLowerCase().includes(e.toLowerCase()) || el.home.fullName.toLowerCase().includes(term.toLowerCase()))
                setAllData(itemSort)
        }

        const onAddedFeed = obj => {
            if(item) {
                const feed ={
                    _id: obj._doc._id,
                    canonical: obj._doc.canonical,
                    matchId: obj._doc.matchId,
                    staffId: obj._doc.staffId,
                    staff:{
                        _id: obj.staff._id,
                        name: obj.staff.name,
                        range: obj.staff.range,
                        telegramLink: obj.staff.telegramLink,
                        avatarUrl: obj.staff.avatarUrl
                    },
                    doc:obj._doc

                }
                const upd = {
                    ...item,
                    feeds: item.feeds.concat({...feed})
                }
                setItem(upd)
            }
        }

        const updCanonical= (result, id) =>{
            if(item){
                let itemCanonical = item.feeds.filter(it => it._id === id)
                let itemNoCanonical = item.feeds.filter(it => it._id !== id).map(item => item.canonical = false)
                itemCanonical[0].canonical = true
                const upd ={
                    ...item, ...itemCanonical
                }
                setItem(upd)
            }
        }

        const saveMatch = async (match) => {
            //setNewMatch(emptyMatch);
            const {date, time, home, away, status} = match;
            const isLongDate = date.split('-')[0].length === 4 ? true : false
            const body = {
                date: isLongDate ? date.slice(2) : date,
                time: time,
                dateTime: isLongDate ? `${date}T${time}:00.7650000Z` : `20${date}T${time}:00.7650000Z`,
                homeId: home._id,
                clubHost: home.fullName,
                clubHostId: home.externalId,
                awayId: away._id,
                clubGuest: away.fullName,
                clubGuestId: away.externalId,
                status: status
            };
            //console.log(body);
            const genMatch = await service.postData(null, 'matches', body)
            //console.log('genMatch', genMatch);
            if (genMatch.success) {
                setLoad(true)
                toast.success(`Матч сохранен ${genMatch.matchId}, список сейчас обновится`, {autoClose: 2000} )
                setNewMatch(null);
            } else {
                toast.danger(`Матч не сохранен`, {autoClose: 1000} )
            }
        }

        return (
            <>
                {newMatch ? <CreateMatch
                    match={emptyMatch}
                    setNewMatch={setNewMatch}
                    saveMatch={saveMatch}
                    clubs={clubs}
                /> : null}

                <div className={`header__text_tab long`}>

                    {tabBtn.map((it) => (
                        <div key={it.id} className={`text__tab_title ${tab === it.id? 'active': ''}`} onClick={() => setTab(it.id)}>
                            <span>{it.name}</span>
                        </div>
                    ))}
                    <Input
                        type='text'
                        value={term}
                        name='name'
                        onChange={handleChange}
                        placeholder='Введите название команды'
                    />
                </div>

                {loader ? <div className='loader'></div> : <div className="match">
                    <div className='match__btn' onClick={() => setNewMatch(emptyMatch)}>
                        <img src={Plus}/>
                        <span>Создать</span>
                    </div>

                    <div className='match__list'>
                        <MatchTable
                            name={tableName}
                            progressIndicator={tab === 'live' ? timeReload : null}
                            data={tab === 'unassigned' ? filterUnassigned(data) : term !== '' ? allData : data[tab].sort(sorters[tab])}
                            setItem={setItem}
                            selected={item ? item._id : null}
                            base={link}
                            setLoad={setLoad}
                        />
                    </div>

                    {item ? (
                        <MatchRightBlock
                            staffData={staffData}
                            item={item}
                            link={link}
                            setLoad={setLoad}
                            load={load}
                            onAddedFeed={onAddedFeed}
                            setItem={setItem}
                            setDataMatch = {setData}
                        />
                    ) : (

                        <div className='match__info'>
                            <img src={Cubock}/>
                            <span className='info__text'>Выберите матч для <br/>редактирования</span>
                        </div>

                    )}
                </div>}
            </>
        )
    })
)

export default Match
