import React,{useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { computed, toJS } from 'mobx';
import Player from '../../assets/img/player.svg'
import Selector from '../Ui/Selector'
import moment from 'moment'

const listSelect =[
    {id:1, name:'Неточный удар', type:'shot'},
    {id:2, name:'Удар в каркас', type:'post'},
    {id:3, name:'Взятие ворот', type:'score'},
    {id:4, name:'Уголовой', type:'corner'},
    {id:5, name:'Нарушение правил', type:'foul'},
    {id:6, name:'Оффсайд', type:'offside'},
    {id:7, name:'Удар в створ', type:'onTarget'},
    {id:8, name:'Предупреждение', type:'yellow'},
    {id:9, name:'Удаление', type:'red'},
    {id:10, name:'Ассист', type:'assist'},
    {id:11, name:'Неточный удар', type:'shot'},
]


const CreateMarker = inject('mainStore')(observer(({ mainStore, onClick, data, rosters, disable}) => {
    const link = computed(() => mainStore.getMainStore("link")).get()
    const navigation = useNavigate();
    const location = useLocation()
    const [active, setActive] = useState('home')
    const [clubId, setClubId] = useState(false)
    const [playerId, setPlayerId] = useState(null)
    const [selectMarker, setSelectMarker] = useState(false)
    const [minutes, setMinutes] = useState(false)
    const [seconds, setSeconds] = useState(false)


    let date = moment().valueOf();

    const onClickClubTab =(tab) => {
        setActive(tab)
        setClubId(tab === 'home' ? data.match.home._id : tab === 'away' ? data.match.away._id : false)
    }

    const onClickPlayer = (id) =>{
        setPlayerId(id)
    }


    let filterDataMinuts = data.markers.filter(el => el.matchMinutes <= +minutes)

    let markerData = {
            matchId: data.match._id,
            feedId: data._id ,
            clubId: data.match[active]._id,
            playerId: playerId,
            // matchTime: +(`${minutes}${seconds}`),
            matchMinutes: +(minutes.length===1 ? 0+minutes :  minutes),
            matchSeconds: +(seconds.length===1 ? 0+seconds :  seconds),
            type:selectMarker,
            clientTimeStamp: filterDataMinuts.length ? filterDataMinuts[0].clientTimestamp + 1000 : data.markers.length===0 ? date : data.markers[0].clientTimestamp + 1000,
            isAdditionalTime: minutes < 90 ? false : true
    }


    let minutesArr = Array.from(Array(121), (_, idx) => idx)
    let secondsArr = Array.from(Array(60), (_, idx) => idx)


    return (
        <div className='modal__create'>
            <span className='modal__create_title'>Создание маркера</span>

            <div className='modal__create_time'>
                <label className='label'>Выбирите время события</label>
                <div className='create_time'>
                    <div>
                        <label className='label'>Выбирите минуты</label>
                        <select onChange={(e)=> setMinutes(e.target.value)} className='time__block'>
                        {minutesArr.map((i)=> (
                            <option value={i} key={i}>{i}</option>
                        ))}
                        </select>
                    </div>
                    <div>
                        <label className='label'>Выбирите секунды</label>
                        <select onChange={(e)=> setSeconds(e.target.value)} className='time__block'>
                        {secondsArr.map((i)=> (
                            <option value={i} key={i}>{i}</option>
                        ))}
                        </select>
                    </div>
                </div>

            </div>
            <div className='modal__create_select'>
                <label className='label'>Выбирите тип события</label>
                <Selector list={listSelect} onChange={setSelectMarker}/>
            </div>

            <label className='label'>Выбирите команду и игрока</label>
            <div className='create__change_name'>

                <div className={`name__home ${active==="home" ? "active": ''}`} onClick={() => onClickClubTab('home')}>
                    <img src={data.match.home.logo}/>
                    <span>{data.match.home.shortName}</span>
                </div>
                <div className={`name__away ${active==="away" ? "active": ''}`} onClick={() => onClickClubTab('away')}>
                    <span>{data.match.away.shortName}</span>
                    <img src={data.match.away.logo}/>
                </div>
            </div>
            <div className='create__player_block'>
            {rosters && rosters.home && rosters.away && rosters[active].map((player, idx) => (
                <div className={`player__block ${playerId === player._id ? 'active' : ''}`} onClick={() => onClickPlayer(player._id)} key={idx}>
                    <img src ={Player}/>
                    <span className='player__block_num' style={{left:`${player.num.length===1 ? '40%' : '35%'}`}}>{player.num}</span>
                    <span className='player__block_name' >{player.surname}</span>
                </div>
            ))}
            </div>

            <div className='modal__create_btn-group'>
                <div className={`btn-group create ${disable ? 'disable' : null}`} onClick={disable ? ()=>{} : () =>  onClick(markerData) }>Создать</div>
                <div className='btn-group cancel' onClick={() =>navigation(-1) }>Отменить</div>
            </div>
        </div>
    )
  }))

export default CreateMarker;
