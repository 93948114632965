const axios = require("axios")
const moment = require("moment")
const CancelToken = axios.CancelToken


const base = `${localStorage.getItem('backLink')}`
//const base = `http://localhost:1100/`

const ADMINTOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MmM4MmY2MjhhM2I0OTYxM2FiOGNlN2QiLCJsb2dpbiI6Im10YWxhcCIsInBhc3N3b3JkIjoibXRhbGFwIn0.jWgoMWcMoXCPmqcwAVYnLfFj-6ERsjbNVot5PZqdrgA';

let headers = {
    "Authorization": `${localStorage.getItem('tkn')}`
}

const instance = axios.create({
    headers: headers,
})

const mod = {}

mod.fetchSummary = async () => {
    try {
        let req = await instance.get(`https://stats.amateum.com/admin/summary`,{
            headers: {
                Authorization: localStorage.getItem('tkn')
            }})

        return req.data
    } catch (e) {
        return {error: true}
    }
}

mod.getPotok = async (link,cb) => {
    try {
        let req = await instance.get(`${link}admin/feeds`,{
            headers: {
                Authorization: localStorage.getItem('tkn')
            }})
        if (req) {
            cb(req.data)
        } else {
            console.log("error", req.data.msg, req.data.err ? req.data.err : "")
            cb(req.data)
        }
    } catch (e) {
        console.log(e)
        cb(false)
    }
}

mod.getMatch = async (link, cb) => {
    try {
        let req = await instance.get(`${link}admin/matches`,{
            headers: {
                Authorization: localStorage.getItem('tkn')
            }})
        if (req) {
            cb(req.data)
        } else {
            console.log("error", req.data.msg, req.data.err ? req.data.err : "")
            cb(req.data)
        }
    } catch (e) {
        console.log(e)
        cb(false)
    }
}

mod.getStaff = async (cb) => {
    try {
        let req = await instance.get(`${localStorage.getItem('backLink')}admin/available_staffs`,{
            headers: {
                Authorization: localStorage.getItem('tkn')
            }})
        if (req) {
            cb(req.data)
        } else {
            console.log("error", req.data.msg, req.data.err ? req.data.err : "")
            cb(req.data)
        }
    } catch (e) {
        console.log(e)
        cb(false)
    }
}

mod.getInit = async (link, cb) => {
    try {
        let req = await instance.get(`${link}admin/init`,{
            headers: {
                Authorization: localStorage.getItem('tkn')
            }})
        if (req) {
            cb(req.data)
        } else {
            console.log("error", req.data.msg, req.data.err ? req.data.err : "")
            cb(req.data)
        }
    } catch (e) {
        console.log(e)
        cb(false)
    }
}

mod.postFeeds = async (data, cb)=> {
    let req = await instance.post(`${localStorage.getItem('backLink')}feeds`, data)
    if (req) {
        cb(req.data)
    }
}

mod.getStaffs = async (link, cb) => {
    try {
        let data = await instance.get(`${link}admin/staffs`)
        if(data){
            return  cb({success: true, data: data.data})
        }
    } catch (e) {
        console.log(e)
        return {error: true}
    }
}

mod.postCanonical = async (id, sourceId, link ,cb )=>{
    let req = await instance.post(`${link}admin/canonicalfeed/${id}`,{sourceId: sourceId})
    if (req) {
        cb(req)
    }
}

mod.getData = async (url) => {
    try {
        const res = await axios.get(base + url, {
            headers: {
                Authorization: localStorage.getItem('tkn') || ADMINTOKEN
            }});
        // console.log('getData res', res.data);
        return res.data
    } catch (e) {
        // console.log('getData err', url, e);
        return {err: true, msg: `getData err ${url}`}
    }
}

mod.postData = async (link, url, body) => {
    try {
        //console.log('postData',`${link}${url}`, body, headers);
        const host = link || base;
        const res = await axios.post(`${host}${url}`, body, {
            headers: {
                Authorization: localStorage.getItem('tkn') || ADMINTOKEN
            }});
        return res.data

    } catch (e) {
        console.log('getData err', `${link}${url}`, e);
        return {err: true, msg: `getData err ${url}`}
    }
}

mod.putData = async (link, url, body, cb) => {
    try {
        // console.log('getData',`${base}${url}`, headers);
        let req = await instance.put(`${link}${url}/${body._id}`, body)
        // console.log(req);
            if(req){
                 cb(req)
            }


    } catch (e) {
        // console.log('getData err', url, e);
        return {err: true, msg: `put data err ${url}`}
    }
}

mod.deleteData = async (link, body, cb) => {
    let req = await instance.put(`${link}staffs/${body._id}`, body)
        if (req) {
            cb(req)
        }
}

mod.deleteFeedId = async (id, link, cb) => {
    let req = await instance.delete(`${link}feeds/${id}`)
        if (req) {
            cb(req)
        }
}


mod.postCompound = async ( data, link, cb ) => {
    let req = await instance.post(`${link}admin/getRosters`, data)
        if(req){
            cb(req)
        }
}

mod.getMarker = async (id, link, cb) => {
    let req = await instance.get(`${link}feedmarkers/${id}`)
        if(req){
            cb(req)
        }
}

mod.postMarker = async ( data, link, cb) => {
    let req = await instance.post(`${link}admin/savemarker`, data)
        if(req){
            cb(req)
        }
}

mod.putMarker = async (data, markerId, link, cb) => {
    let req = await instance.put(`${link}admin/moderatemarker/${markerId}`, data)
        if(req){
            cb (req)
        }
}

mod.deleteMarker = async(markerId, link, cb) => {
    let req = await instance.delete(`${link}admin/deletemarker/${markerId}`)
        if(req){
            cb(req)
        }
}
//module.exports = mod
export default mod

const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min //Максимум и минимум включаются
}
