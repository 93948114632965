import React, {useState, useEffect, useRef} from "react"
import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"
import Selector from '../../../Ui/Selector'
import Player from '../../../../assets/img/player.svg'
import {useParams, useLocation, useNavigate, Redirect} from "react-router-dom"


const dataChoice = [
    {id:1, title:'Событие не в кадре (нет повтора)', type:'unvisible'},
    {id:2, title:'Не виден номер игрока', type:'missingPlayer'},
]

const listSelect =[
    {id:1, name:'Неточный удар', type:'shot'},
    {id:2, name:'Удар в каркас', type:'post'},
    {id:3, name:'Взятие ворот', type:'score'},
    {id:4, name:'Уголовой', type:'corner'},
    {id:5, name:'Нарушение правил', type:'foul'},
    {id:6, name:'Оффсайд', type:'offside'},
    {id:7, name:'Удар в створ', type:'onTarget'},
    {id:8, name:'Предупреждение', type:'yellow'},
    {id:9, name:'Удаление', type:'red'},
    {id:10, name:'Ассист', type:'assist'},
    {id:11, name:'Неточный удар', type:'shot'},

]


const RightBlockMarkers = inject("mainStore")(observer(({mainStore, data, link, setItem, setRosters}) => {
    const [open, setOpen] = useState(false)
    const [nameRadio, setNameRadio] = useState(false)
    const [active, setActive] = useState('home')
    const [compound, setCompound] = useState(false)
    const [selectMarker, setSelectMarker] = useState(false)
    const [marker, setMarker] = useState({})
    const [dataMarker, setDataMarker] = useState([])
    const [playerId, setPlayerId] = useState(false)
    const [clubId, setClubId] = useState(false)
    const [checkbox, setCheckbox] = useState(false)
    const [scrollId, setScrollId] = useState(false)
    const [loader, setLoader] = useState(false)

    useEffect(()=>{
        setLoader(true)
        mainStore.postCompoundData( data.match._id, link,(result) => {
            if(result.data){
                setCompound(result.data.rosters)
                setRosters(result.data.rosters)
            }
        })
        mainStore.getMarkerData(data._id, link, result=>{
            if(result){
                setLoader(false)
                setDataMarker(result.data)
            }

        })
        setOpen(false)
    },[link, data])





    const onClickMarker = (item,idx) => {
        setTimeout(() => {setOpen(true)},1000)
        setScrollId(item._id)
        setMarker({item, idx})
        setActive(item.clubId === data.match.home._id ? 'home': 'away')
        setClubId(item.clubId)
        setPlayerId(item.person.player ? item.person.player._id : false)
        setSelectMarker(item.type)
        setCheckbox(item.person.player ? false : true)
    }

    useEffect(() => {
        let id = scrollId ? scrollId : false
        if (id){
            let elem = document.getElementById(`${id}`)
            if (elem && !open) {elem.scrollIntoView({behavior: 'smooth'})}
        }
    }, [scrollId,open])

    const onClickClubTab =(tab) => {
        setActive(tab)
        setClubId(tab === 'home' ? data.match.home._id : tab === 'away' ? data.match.away._id : false)
    }

    const onCheckBox =(e)=>{
        setCheckbox(e.target.checked)
        if(!e.target.checked){
            setNameRadio(false)
        }
    }

    const onClickRadioBtn = (tab) => {
        if(checkbox){
            setNameRadio(tab)
        }else{
            setNameRadio(false)
        }
    }

    const onClickChangeMarker = ()=>{
        let dataNew ={
            playerId: playerId,
            clubId: clubId,
            type: selectMarker,
            needRefinement: false,
            nonpersonalizable: nameRadio.type ? nameRadio.type : null
        }

        mainStore.putMarkerData(dataNew, marker.item.markerId, link,(result =>{
            if(result.data.success){
                setOpen(false)
                let newMarker = {
                    ...marker.item, playerId:playerId, type: selectMarker.type || selectMarker,
                }
                let indexMarker = marker.idx
                data.markers[indexMarker] = newMarker
                let dataeee = {...data}
                setItem(dataeee)
            }
        } ) )
    }

    const onClickDeleteMarcer = () => {
        mainStore.deleteMarker( marker.item.markerId, link , result=>{
            if(result.data.success){
                setOpen(false)
                let arrNewMarker =  data.markers.filter(el => el._id !== marker.item._id)
                let newItem = {
                    ...data, markers:arrNewMarker
                }
                setItem(newItem)
            }
        })
    }

    let arrMatch = compound && compound.home && compound.away ?  compound['home'].concat(compound['away']) : []


    return (<>
                {open ?
                    <div className='right__block_markers_change'>
                        <div className='markers__change_name'>
                            <div className={`name__home ${active==="home" ? "active": ''}`} onClick={() => onClickClubTab('home')}>
                                <img src={data.match.home.logo}/>
                                <span>{data.match.home.shortName}</span>
                            </div>
                            <div className={`name__away ${active==="away" ? "active": ''}`} onClick={() => onClickClubTab('away')}>
                                <span>{data.match.away.shortName}</span>
                                <img src={data.match.away.logo}/>
                            </div>
                        </div>
                        <Selector list={listSelect} onChange={setSelectMarker} marker={marker.item}/>

                        <div className='markers__change_player'>
                        {compound && compound[active].map((ros, idx)=>(
                                <div className={`player__block ${playerId === ros._id ? 'active' : ''}`} key={idx} onClick={()=> setPlayerId(ros._id)}>
                                    <img src={Player}/>
                                    <span className='player__block_name' >{ros.surname}</span>
                                    <span className="player__block_number" style={{left:`${ros.num.length===1 ? '40%' : '35%'}`}}>{ros.num}</span>
                                </div>
                            ))}
                        </div>

                        <div className='markers_change_check'>
                            <input type='checkbox' checked={checkbox} onChange={(e) => onCheckBox(e) }/>
                            <span>Невозможно персонализировать</span>
                        </div>

                        {dataChoice.map((it, idx) => (
                            <div className='markers__change_radio'  onClick = {checkbox ? () => onClickRadioBtn(it) : ()=>{}} key={idx}>
                                <div className={`radio ${nameRadio.title === it.title && checkbox ? 'act': ''}`}>
                                    <div className={`radio__activ ${nameRadio.title === it.title && checkbox ? 'activ':''}`}></div>
                                </div>
                                <span className={`${nameRadio.title === it.title  && checkbox ? 'active' : ''}`}>{it.title}</span>
                            </div>
                        ))}

                        <div className='markers__change_group-btn'>
                            <div className='group-btn' onClick={()=> onClickChangeMarker()}>Сохранить маркер</div>
                            <div className='group-btn delete' onClick={() => onClickDeleteMarcer()}>Удалить маркер</div>
                            <div className='group-btn back' onClick={() => setOpen(false)}>Назад к списку</div>
                        </div>

                    </div>

                    :

                loader ? <div className='loader'></div>
                :
                dataMarker.sort((a,b )=> a.matchTime - b.matchTime ).map((item, idx) => {

                    let type = item && item.type ? item.type : ''
                    let typeItem
                    switch (type){
                        case 'shot':
                            typeItem = "Неточный удар"
                            break;
                        case 'post':
                            typeItem = "Удар в каркас"
                            break;
                        case 'score':
                            typeItem = "Взятие ворот"
                            break;
                        case 'corner':
                            typeItem ="Уголовой"
                            break;
                        case 'foul':
                            typeItem = "Нарушение правил"
                            break;
                        case 'offside':
                            typeItem ="Оффсайд"
                            break;
                        case 'onTarget':
                            typeItem = "Удар в створ"
                            break;
                        case 'yellow':
                            typeItem = "Предупреждение"
                            break;
                        case 'red':
                            typeItem = "Удаление"
                            break;
                        case 'assist':
                            typeItem = "Ассист"
                            break;
                    }

                    let timeMinuts = `${Math.floor(item.matchTime/60)}`
                    let timeSecond = `${item.matchTime - timeMinuts * 60}`

                    let half_1 = item.matchMinutes < 45 && !item.isAdditionalTime ? '1 тайм' : item.isAdditionalTime && item.matchMinutes < 50 ? '1 тайм' : false

                    let half_2 = item.matchMinutes < 90 && !item.isAdditionalTime ? '2 тайм' : item.isAdditionalTime && item.matchMinutes>=90 ? '2 тайм' : false

                    return(
                        <div key={idx} id={item._id}>

                            <div className={`right__block_markers ${item.person.player === null ? 'red': item.person.needRefinement ? "green" : ''}`} key={item._id} onClick={() => onClickMarker(item,idx)}>

                                {item.clubId === data.match.home._id ? <img className='markers__img' src={`${data.match.home.logo}`}/> : <img className='markers__img' src={`${data.match.away.logo}`}/>}
                                <span className='marker__half'>{half_1 ? half_1 : half_2 }</span>

                                <div className='markers__time'>{`${timeMinuts.length === 1 ? `0${timeMinuts} ` : timeMinuts } : ${timeSecond.length === 1 ? `0${timeSecond} ` : timeSecond }`}</div>

                                <div className='markers__text'>
                                    <span className='markers__text_title'>{typeItem}</span>
                                    <span className='markers__text_name'>{item.person && item.person.player ? item.person.player.surname : ''}</span>
                                </div>

                                <span className='markers__number'>{item.person && item.person.player ? item.person.player.num : ''}</span>
                            </div>

                        </div>
                    )
                }

                    )}

                    {!open ? (
                        <div className='right__block_btn-wrap'>
                            <div className={`right__block_btn markers ${data.match.metaInfo ? '' : 'disablet'}`}>
                                {data.match.metaInfo ? <a href={data.match.metaInfo ? `${data.match.metaInfo.publicLink}` : ''} target="_blank">Открыть трансляцию</a> : "Открыть трансляцию"}
                            </div>
                        </div>
                    ) : null}

        </>
    )
})
)

export default RightBlockMarkers
