import React from 'react'
import '../../assets/scss/input.scss';

const Input=({ type, value, name, label, onChange, placeholder, classterm }) => {

    return (
        <div className={`custom__input-container ${classterm || ''}`}>
            {label ? <label className='custom__label'>{label}</label> : null}
            <input name={name} onChange={(e)=>onChange(e.target.value)} type={type} placeholder={placeholder} className='input' value={value} autoComplete='off'/>
        </div>
    )
}

export default Input
