import React, {useState, useEffect, useRef} from "react"
import RightBlockSummary from './RightBlockSummary'
import RightBlockMarkers from './RightBlockMarker'
import {useParams, useLocation, useNavigate, Redirect} from "react-router-dom"
import Modal from '../../../Modal'

import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"

const RightBlock = inject("mainStore")(observer(({mainStore, data, link, setItem}) => {

    const [active, setActive] = useState('summary')
    const [rosters, setRosters] = useState(false)
    const [disable, setDisable]= useState(false)
    const navigation = useNavigate();
    const location = useLocation()



    const onClickCreateMarker = () =>{
        navigation(location.pathname+'?modal=create-marker', {
                state: {
                    modal: 'createMarker'
                }
            }
        )
    }

    const onClickMarker = (markerData)=>{
        setDisable(true)
            mainStore.postMarkerCreate(markerData, link, result =>{
                if(result){
                    let dataMarkerNew = data.markers.concat(markerData)
                    navigation(-1)
                    setItem({...data, markers: dataMarkerNew})
                    setDisable(false)
                }
        })
    }

    return (
        <div className='potoki__right_block' id='markerBlock'>
                <Modal data={data} rosters={rosters} onClick={onClickMarker} disable={disable}/>
                <div className='right__block_tab'>
                    <div className={`block__tab_name1 ${active === 'summary' ? 'activ' : '' }`} onClick={()=>{setActive('summary')}}>Сводка</div>
                    <div className={`block__tab_name2 ${active !== 'summary' ? 'activ' : '' }`} onClick={()=>{setActive('markers')}}>Маркеры</div>
                </div>
                {active==='summary' ? null : <div className='right__block_add-marker' onClick={() => onClickCreateMarker()}>Создать маркер</div>}
                {active==='summary' ? <RightBlockSummary data={data}/> : <RightBlockMarkers data={data} link={link} setItem={setItem} setRosters={setRosters}/>}
        </div>
    )
})
)

export default RightBlock
