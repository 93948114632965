import React, {useState, useEffect} from 'react'
import moment from 'moment'

const DatePikerTime=({timer="", onChange, id}) => {

    const [time, setTime] = useState(false)

        useEffect(()=>{
            setTime(timer)
        },[id])

        const onChengeTarget = (e)=> {
            setTime(e)
        }


    return (
        <div className='date-piker'>
            <span className='date-piker__title'>Время</span>
            <input name='time' onChange={(e) => onChengeTarget(e.target.value)} type='time'className='date-piker__input' value={time} onBlur={() => onChange(time)}/>
        </div>
    )
}

export default DatePikerTime
