import React, {useState, useEffect, useRef} from "react"
import {useParams, useLocation, Redirect} from "react-router-dom"
import PotokTable from '../Potok/PotokTable'
import RightBlock from '../Potok/RightBlock/RightBlock'
import Potoki from '../../../assets/img/potoki.svg'
import { Input } from '../../Common'
import Plus from '../../../assets/img/plus.svg'
import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"
import service from '../../../stores/service'
import { debounce } from 'throttle-debounce';

const tabBtn= [
    {id: 'live', name:'LIVE'},
    {id: 'upcoming', name:'Предстоящие'},
    {id: 'finished', name:'Прошедшие'}
]

const sorters = {
    "live": (a, b) => a.match.unix - b.match.unix,
    "upcoming": (a, b) => a.match.unix - b.match.unix,
    "finished": (a, b) => b.match.unix - a.match.unix,
}

const timeReload = 20000;

const Potok = inject("mainStore")(observer(({mainStore}) => {
        const link = computed(() => mainStore.getMainStore("link")).get()
        const [data, setData] = useState({finished:[], live:[], upcoming:[]})
        //const [allData, setAllData] = useState([])
        const [filtred, setFiltred] = useState([])
        const [item, setItem]= useState(false)
        const [tab, setTab] = useState('live')
        const [term, setTerm] = useState('');
        const [openRight, setOpenRight] = useState(false)
        const [loader, setLoader] = useState(false)
        const [clubterm, setClubTerm] = useState('');

        useEffect(() => {
            setLoader(true)
            mainStore.getPotokData(link,(result) => {
                if(result){
                    setLoader(false)
                    setData(result)
                    setFiltred(result[tab])
                }
            })
        },[link])

        const getDataRef = useRef(null)

        const getFeedsData = async (clubterm) => {
            const fdata = clubterm && clubterm.length > 3 ? await service.getData(`admin/feeds/?clubterm=${clubterm}`) : await service.getData(`admin/feeds`);
            if (fdata) {
                setData(fdata);
                setFiltred(fdata[tab])
                if (item) {
                    const it = fdata[tab].find(m => m._id.toString() === item._id.toString() );
                    if (it) {
                        setItem(it)
                    } else {
                        setItem(null);
                    }
                }
            }
            setLoader(false)
        }

        useEffect(() => {
            let timeId

            if (term) {
                if (clubterm) {
                    const itemSort = data[tab].filter((el) => el.staff.name.toLowerCase().includes(term.toLowerCase()) && (el.match.home.fullName.toLowerCase().includes(clubterm.toLowerCase()) || el.match.away.fullName.toLowerCase().includes(clubterm.toLowerCase())))
                    setFiltred(itemSort)
                } else {
                    const itemSort = data[tab].filter((el) => el.staff.name.toLowerCase().includes(term.toLowerCase() ))
                    setFiltred(itemSort)
                }

            } else if (clubterm){
                const itemSort = data[tab].filter((el) => el.match.home.fullName.toLowerCase().includes(clubterm.toLowerCase()) || el.match.away.fullName.toLowerCase().includes(clubterm.toLowerCase()))
                setFiltred(itemSort)
            } else {
                setFiltred(data[tab].sort(sorters[tab]))
            }
            if (tab === 'live') {
                timeId = setInterval(getFeedsData, timeReload, clubterm);
                return () => {
                    if (timeId) {
                        clearInterval(timeId)
                    }
                }
            }
            //setFiltred(data[tab])
        }, [tab, term, clubterm])

        useEffect(() => {
            if (clubterm){
                //getFeedsData(clubterm)
                //timeId = setTimeout(getFeedsData, 500, clubterm);
                if (clubterm.length > 3) {
                    setLoader(true)

                    const debounceFunc = debounce(1000, async clubterm => await getFeedsData(clubterm));
                    debounceFunc(clubterm)
                }
           } else if (data && data[tab] && data[tab].length) {
               getFeedsData();
           }
        }, [clubterm])

        /*useEffect(() => {
            if (term) {
                if (clubterm) {
                    const itemSort = data[tab].filter((el) => el.staff.name.toLowerCase().includes(term.toLowerCase()) && (el.match.home.fullName.toLowerCase().includes(clubterm.toLowerCase()) || el.match.away.fullName.toLowerCase().includes(clubterm.toLowerCase())))
                    setFiltred(itemSort)
                } else {
                    const itemSort = data[tab].filter((el) => el.staff.name.toLowerCase().includes(term.toLowerCase() ))
                    setFiltred(itemSort)
                }

            } else if (clubterm){
                const itemSort = data[tab].filter((el) => el.match.home.fullName.toLowerCase().includes(clubterm.toLowerCase()) || el.match.away.fullName.toLowerCase().includes(clubterm.toLowerCase()))
                setFiltred(itemSort)
            } else {
                setFiltred(data[tab].sort(sorters[tab]))
            }
        }, [term, clubterm])*/

        /*useEffect(() => {
            let timeId = null
            if (tab === 'live' && getDataRef && getDataRef.current){
                timeId = setInterval(getDataRef.current, timeReload);
            }
            console.log('timeId', timeId);
            return () => {
                console.log('timeId', timeId);
                if (timeId) {
                    clearInterval(timeId)
                }
            }
        }, [tab])*/

        const handleChange = (e) => {
            setTerm(e)
        }

        return (
            <>
                <div className='header__text_tab'>
                    {tabBtn.map((it)=>(
                        <div key={it.id} className={`text__tab_title ${tab===it.id? 'active': ''}`} onClick={() => setTab(it.id)}>
                            <span>{it.name}</span>
                        </div>
                    ))}
                    <Input
                        type='text'
                        value={term}
                        name='name'
                        onChange={e => setTerm(e)}
                        placeholder='Введите ФИО статиста'
                    />
                    <Input
                        type='text'
                        value={clubterm}
                        name='clubterm'
                        onChange={e => setClubTerm(e)}
                        placeholder='Введите клуб, чтобы получить все данные'
                        classterm='clubterm'
                    />
                </div>

            {loader ? <div className='loader'></div> : <div className="potoki">
                    <div className='potoki__btn'>
                        <img src={Plus}/>
                        <span>Создать</span>
                    </div>

                    <div className='potoki__list'>
                        {data ? <PotokTable
                                    progressIndicator={tab === 'live' && !clubterm ? timeReload : null}
                                    data={filtred}
                                    onClick={setItem}
                                    selected={item._id}
                                    setOpenRight={setOpenRight}
                                />
                                : null}
                    </div>

                    {openRight ? (
                        <RightBlock
                            data={item}
                            link={link}
                            setItem={setItem}
                        />
                    ) : (
                        <div className='potoki__info'>
                            <img src={Potoki}/>
                            <span className='info__text'>выберите поток для<br/> редактирования</span>
                        </div>
                    )}
                </div>}
            </>
        )
    })
)


export default Potok
