import React, { useEffect } from 'react'

export const checkSwipeDirection = (e, startX, startY) => {


    let dir = ''
    let pageX = e.touches[0].pageX
    let pageY = e.touches[0].pageY

    let distX = pageX - startX;
    let distY = pageY - startY;

    if (Math.abs(distX) > Math.abs(distY)) {
        dir = (distX < 0) ? 'left' : 'right'
    }else {
        dir = (distY < 0) ? 'up' : 'down'
    }

    return dir
}

export const useClickOutside = (val, ref, cb) => {

    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            cb()
        }
    }

    useEffect(() => {
        if (val) {
            document.addEventListener('click', handleClick, { capture: true })
        } else {
        	document.removeEventListener('click', handleClick, { capture: true })
        }

        return () => {
            document.removeEventListener('click', handleClick, { capture: true })
        }
    })
}
