import React, {useState, useEffect, useRef} from "react"
import {useParams, useLocation, useNavigate, Redirect} from "react-router-dom"
import DatePiker from '../../Ui/DatePiker'
import DatePikerTime from '../../Ui/DatePikerTime'
import UserBlock from '../../Ui/UserBlock'
import CopiLink from '../../Ui/CopiLink'
import Selector from '../../Ui/Selector'
import Switch from '../../Ui/Switch'
import Plus from '../../../assets/img/plus.svg'
import {inject, observer} from "mobx-react"
import {computed, toJS} from "mobx"
import Modal from '../../Modal'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
moment.locale('ru')

const MatchRightBlock = inject("mainStore")(observer(({ mainStore, staffData, item, link ,setLoad, load, onAddedFeed, setItem, setDataMatch}) => {

    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [staffId, setStaffId] = useState(false)
    const [form, setForm] = useState(false);
    const [basic, setBasic] = useState(false)
    const [clon, setClon] = useState(false)
    const [time, setTime] = useState(false)
    const [date, setDate] = useState(false)
    const navigation = useNavigate();
    const location = useLocation()

    useEffect(()=>{
        setData(item)
        setForm(item)
    },[item])


    // useEffect(() => {
    //     if (data && data._id) {
    //         setForm({
    //             _id: data._id,
    //             date: data.date || '',
    //             time: data.time || '',
    //             isManual: data.isManual || false
    //             //publicLink: data.metaInfo && data.metaInfo.publicLink ? data.metaInfo.publicLink : '',
    //             //externalId: data.externalId || ''
    //         })
    //     }
    // }, [data])

    const AddFeeds = () => {
        let dataNew = {
            matchId: data._id,
            staffId: staffId._id
        }
        if(staffId){
            setLoad(!load)
            mainStore.sendPostFeeds(dataNew, link, (added) => {
                if(added){
                    setOpen(false)
                    onAddedFeed(added)
                }
                if (basic && added){
                    onClickCanonical(added._doc._id,'' , link)
                }
                if (clon){
                    onClickCanonical(added._doc._id, clon ? added._doc._id : null, link)
                }
            })
        }
    }

    const onClickCanonical = (id, sourceId, link) => {
        if(id){
            mainStore.sendPostCanonical(id, sourceId, link, (result) => {

                if(result){
                    let itemCanonical = item.feeds.filter(it => it._id === id)
                    let itemNoCanonical = item.feeds.filter(it => it._id !== id).map(item => item.canonical = false)
                    itemCanonical[0].canonical = true
                    const upd ={
                        ...item
                    }
                    setItem(upd)
                }
            })
        }
    }

    const onClickDelete = (feed, link) => {
        if(feed.snapshot.matchSeconds ===0 && feed.snapshot.matchMinutes ===0 && feed.canonical === true){
            navigation(location.pathname+'?modal=notification', {
                    state: {
                        modal: 'notification'
                    }
                }
            )
        }else{mainStore.deleteFeed(feed._id, link, (result) => {
                if(result){
                    let itemFilter = item.feeds.filter(it => it._id !== feed._id)
                    setDataMatch(result)
                    setItem({...data, feeds:itemFilter})

                }
            })
        }
    }

    const onHandleDate = (date) => {
        const formatDate = moment(date, 'YYYY-MM-DD').format('YY-MM-DD');
        setForm({...form, date: formatDate })
    }

    const onHandleTime = (timeNew) => {
        setForm({...form, time: timeNew})
    }

    const onHandleTest = (e) => {
        setForm({...form, isManual: e.target.checked })
    }


    const onChangeCopiLinkBr =(e)=>{
        setForm({...form, metaInfo:{publicLink: e}})
    }

    const onChangeCopiLinkEx = (e) => {
        setForm({...form, externalId: e})
    }

    const onSaveChanges = () => {
        mainStore.sendPostData(link,'matches',form, result=>{
            if(result){
                toast.success('Матч сохранен', {position: "top-right", autoClose: 1000 })
                setDataMatch(result)
            }
        })

    }

    const isCanonical = ()=>{
        return data.feeds.some(item=>
                true===item.canonical
            )
    }

    return (
        <div className='match__right_block'>
            <Modal />
            {data && data.home ?
                <div className="right__block_title">

                    <div className='block__title_com'>
                        <span>{data.home.fullName}</span>
                        <img src={data.home.logo} className='block__title_com_img'/>
                    </div>

                    <span>:</span>

                    <div className='block__title_com'>
                        <img src={data.away.logo} className='block__title_com_img'/>
                        <span>{data.away.fullName}</span>
                    </div>

                </div> : null
            }

            <div className='right__block_date'>
                <DatePiker onChange={onHandleDate} date={form.date} id={form._id}/>
                <DatePikerTime onChange={onHandleTime} timer={form.time} id={form._id}/>
            </div>

            <div className='right__block_potok'>
                <div className='block__potok_title'>

                    <span className='potok__title_name'>Потоки</span>

                    {!open ? <span className='potok__title_btn' onClick={()=>setOpen(true)}>Добавить</span> : null}
                </div>

                {open && <div className='block__potok_add'>

                    <Selector list={staffData} onChange={setStaffId} plach='Выбери статиста'/>

                    {isCanonical() ?
                        <>
                            <div className='potok__add_switch'>
                                <Switch active={basic} setActive={setBasic}/>
                                <span>Сделать основным</span>
                            </div>

                            <div className='potok__add_switch'>
                                <Switch active={clon} setActive={setClon}/>
                                <span>Клонировать текущий основной</span>
                            </div>
                        </>
                    :
                        null
                    }

                    <div className={`potok__add_btn ${staffId ? "" : 'disablet'}`} onClick ={() => AddFeeds()}><img src={Plus}/>Добавить фид</div>

                </div>}

                {data && data.feeds && data.feeds.map((item) => (
                    <div key={item._id}>
                        <UserBlock
                            data={item}
                            isCanonical={item.canonical || data.feeds.length === 1}
                            onClickCanonical={onClickCanonical}
                            link={link}
                            onClickDelete={onClickDelete}
                        />
                    </div>
                ))}

            </div>

            <div className='right__block_link'>
                <CopiLink label='Бродкаст' link={true} title={data.metaInfo && data.metaInfo.publicLink ? data.metaInfo.publicLink : null  } onChange={onChangeCopiLinkBr}/>
                <CopiLink label='External ID' title={data.externalId} onChange={onChangeCopiLinkEx}/>

                 <div className='block__link_check'>
                    <input id='isManual' type='checkbox' name='isManual' checked={data.isManual} onChange={onHandleTest}/>
                    <label for='isManual' className="block__link_check-text">Тестовый матч</label>
                </div>

            </div>

            <div className='right__block_btn'  onClick={() => onSaveChanges(form) }>Сохранить</div>
        </div>
    )
})
)

export default MatchRightBlock
